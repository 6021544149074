import { default as indexGnqeV2ZHBLMeta } from "/opt/apps/.build/apps/web/app/pages/about-us/index.vue?macro=true";
import { default as indexwu1q0qnZdoMeta } from "/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue?macro=true";
import { default as indexvMGYyH2lG5Meta } from "/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue?macro=true";
import { default as indexw7ZV3iZwBdMeta } from "/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue?macro=true";
import { default as indexjQ0wL5DR5zMeta } from "/opt/apps/.build/apps/web/app/pages/business/index.vue?macro=true";
import { default as _91token_93apbrwjeqmnMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue?macro=true";
import { default as indexhjsSujXxn7Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue?macro=true";
import { default as _91code_936MhlyScz2zMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue?macro=true";
import { default as index950K5k0DHGMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue?macro=true";
import { default as indexuxIZCcDCljMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue?macro=true";
import { default as indexqkNzWNBKgcMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue?macro=true";
import { default as indexjhQHxFTNhxMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue?macro=true";
import { default as sign_45outFI17sBYw4bMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue?macro=true";
import { default as indexqdT2VB2zXhMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue?macro=true";
import { default as dashboardDQduFRXX37Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue?macro=true";
import { default as indexAcpFwjW6WMMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue?macro=true";
import { default as indexw9xgASoMyPMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue?macro=true";
import { default as indexCQtCMgFsaaMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue?macro=true";
import { default as payIQATXowjWtMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue?macro=true";
import { default as checkout7ScQEVfVtxMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue?macro=true";
import { default as indexa3c8kUjm3QMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue?macro=true";
import { default as indexTC8lr75bZHMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue?macro=true";
import { default as dashboardfMpF22Dt0pMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue?macro=true";
import { default as activationbtKGvXyKMEMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue?macro=true";
import { default as payeu8dMxqna0Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue?macro=true";
import { default as checkout5bal2VtYqeMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue?macro=true";
import { default as indexyUhCLKJqkAMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue?macro=true";
import { default as indexrAZMFHCNIQMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue?macro=true";
import { default as dashboardfzm70OO0oNMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue?macro=true";
import { default as indexnk30GuiXlkMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue?macro=true";
import { default as room1VrmeeYJMaMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue?macro=true";
import { default as confirmzrKuDy5VspMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue?macro=true";
import { default as rescheduley0Xik6hLmOMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue?macro=true";
import { default as reviewegon8COXRrMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue?macro=true";
import { default as calendar8vL0a7KBzwMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/calendar.vue?macro=true";
import { default as dashboardgjXAAV0QxcMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue?macro=true";
import { default as indexbKrxEjr0WoMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue?macro=true";
import { default as indexM5pJTer7uKMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue?macro=true";
import { default as indexgYNnwH9mhrMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue?macro=true";
import { default as indexearmxnSW74Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue?macro=true";
import { default as indexrANlcaGzIkMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue?macro=true";
import { default as indexTE0oIYlTfIMeta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue?macro=true";
import { default as indexqT9fQ4biE7Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue?macro=true";
import { default as indexKdqP0kfeJ1Meta } from "/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue?macro=true";
import { default as index9ZDfzXUde1Meta } from "/opt/apps/.build/apps/web/app/pages/contacts/index.vue?macro=true";
import { default as indexUDKTAnHz0RMeta } from "/opt/apps/.build/apps/web/app/pages/emergency/index.vue?macro=true";
import { default as indexDQzhMz3lQPMeta } from "/opt/apps/.build/apps/web/app/pages/family/index.vue?macro=true";
import { default as index3kNQOa4RUqMeta } from "/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue?macro=true";
import { default as indexXoOQI5DaMsMeta } from "/opt/apps/.build/apps/web/app/pages/index.vue?macro=true";
import { default as indexITWAFCLP3hMeta } from "/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue?macro=true";
import { default as indexE5E35YQf7QMeta } from "/opt/apps/.build/apps/web/app/pages/kids/index.vue?macro=true";
import { default as bookingBfCXOC7Uv3Meta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue?macro=true";
import { default as category8bqVYRZoNEMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue?macro=true";
import { default as childhood_ageQI7UyFsaMBMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue?macro=true";
import { default as finishedVMKbZ5UomkMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue?macro=true";
import { default as initialGRVIvrzbpHMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue?macro=true";
import { default as psychologisteL3jaEITLpMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue?macro=true";
import { default as requestsRV80EPlnslMeta } from "/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue?macro=true";
import { default as indexkpu9osV6TLMeta } from "/opt/apps/.build/apps/web/app/pages/matching/index.vue?macro=true";
import { default as successicrXSmHQ0iMeta } from "/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue?macro=true";
import { default as _91slug_93GwtDJylv5IMeta } from "/opt/apps/.build/apps/web/app/pages/pages/[slug].vue?macro=true";
import { default as tech_45problems4auPv3oAUCMeta } from "/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue?macro=true";
import { default as _91payload_93FlG44H0AgkMeta } from "/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue?macro=true";
import { default as _91payload_93EGN54YiNlKMeta } from "/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue?macro=true";
import { default as index0LW3gAcR7MMeta } from "/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue?macro=true";
import { default as indexGPaXbRFSUxMeta } from "/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue?macro=true";
import { default as registeruNpdRfmGrmMeta } from "/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue?macro=true";
import { default as _91slug_93hc2ieDyy50Meta } from "/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue?macro=true";
import { default as indexD9QpfRa9f8Meta } from "/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue?macro=true";
export default [
  {
    name: indexGnqeV2ZHBLMeta?.name ?? "about-us___uk",
    path: indexGnqeV2ZHBLMeta?.path ?? "/about-us",
    meta: indexGnqeV2ZHBLMeta || {},
    alias: indexGnqeV2ZHBLMeta?.alias || [],
    redirect: indexGnqeV2ZHBLMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexGnqeV2ZHBLMeta?.name ?? "about-us___en",
    path: indexGnqeV2ZHBLMeta?.path ?? "/en/about-us",
    meta: indexGnqeV2ZHBLMeta || {},
    alias: indexGnqeV2ZHBLMeta?.alias || [],
    redirect: indexGnqeV2ZHBLMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexGnqeV2ZHBLMeta?.name ?? "about-us___es",
    path: indexGnqeV2ZHBLMeta?.path ?? "/es/about-us",
    meta: indexGnqeV2ZHBLMeta || {},
    alias: indexGnqeV2ZHBLMeta?.alias || [],
    redirect: indexGnqeV2ZHBLMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexGnqeV2ZHBLMeta?.name ?? "about-us___ru",
    path: indexGnqeV2ZHBLMeta?.path ?? "/ru/about-us",
    meta: indexGnqeV2ZHBLMeta || {},
    alias: indexGnqeV2ZHBLMeta?.alias || [],
    redirect: indexGnqeV2ZHBLMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexwu1q0qnZdoMeta?.name ?? "blog-category___uk",
    path: indexwu1q0qnZdoMeta?.path ?? "/blog/:category?",
    meta: indexwu1q0qnZdoMeta || {},
    alias: indexwu1q0qnZdoMeta?.alias || [],
    redirect: indexwu1q0qnZdoMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwu1q0qnZdoMeta?.name ?? "blog-category___en",
    path: indexwu1q0qnZdoMeta?.path ?? "/en/blog/:category?",
    meta: indexwu1q0qnZdoMeta || {},
    alias: indexwu1q0qnZdoMeta?.alias || [],
    redirect: indexwu1q0qnZdoMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwu1q0qnZdoMeta?.name ?? "blog-category___es",
    path: indexwu1q0qnZdoMeta?.path ?? "/es/blog/:category?",
    meta: indexwu1q0qnZdoMeta || {},
    alias: indexwu1q0qnZdoMeta?.alias || [],
    redirect: indexwu1q0qnZdoMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwu1q0qnZdoMeta?.name ?? "blog-category___ru",
    path: indexwu1q0qnZdoMeta?.path ?? "/ru/blog/:category?",
    meta: indexwu1q0qnZdoMeta || {},
    alias: indexwu1q0qnZdoMeta?.alias || [],
    redirect: indexwu1q0qnZdoMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvMGYyH2lG5Meta?.name ?? "blog-category-post___uk",
    path: indexvMGYyH2lG5Meta?.path ?? "/blog/:category()/:post()",
    meta: indexvMGYyH2lG5Meta || {},
    alias: indexvMGYyH2lG5Meta?.alias || [],
    redirect: indexvMGYyH2lG5Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvMGYyH2lG5Meta?.name ?? "blog-category-post___en",
    path: indexvMGYyH2lG5Meta?.path ?? "/en/blog/:category()/:post()",
    meta: indexvMGYyH2lG5Meta || {},
    alias: indexvMGYyH2lG5Meta?.alias || [],
    redirect: indexvMGYyH2lG5Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvMGYyH2lG5Meta?.name ?? "blog-category-post___es",
    path: indexvMGYyH2lG5Meta?.path ?? "/es/blog/:category()/:post()",
    meta: indexvMGYyH2lG5Meta || {},
    alias: indexvMGYyH2lG5Meta?.alias || [],
    redirect: indexvMGYyH2lG5Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvMGYyH2lG5Meta?.name ?? "blog-category-post___ru",
    path: indexvMGYyH2lG5Meta?.path ?? "/ru/blog/:category()/:post()",
    meta: indexvMGYyH2lG5Meta || {},
    alias: indexvMGYyH2lG5Meta?.alias || [],
    redirect: indexvMGYyH2lG5Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/blog/[category]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: indexw7ZV3iZwBdMeta?.name ?? "business-customer-register___uk",
    path: indexw7ZV3iZwBdMeta?.path ?? "/business/customer/register",
    meta: indexw7ZV3iZwBdMeta || {},
    alias: indexw7ZV3iZwBdMeta?.alias || [],
    redirect: indexw7ZV3iZwBdMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexw7ZV3iZwBdMeta?.name ?? "business-customer-register___en",
    path: indexw7ZV3iZwBdMeta?.path ?? "/en/business/customer/register",
    meta: indexw7ZV3iZwBdMeta || {},
    alias: indexw7ZV3iZwBdMeta?.alias || [],
    redirect: indexw7ZV3iZwBdMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexw7ZV3iZwBdMeta?.name ?? "business-customer-register___es",
    path: indexw7ZV3iZwBdMeta?.path ?? "/es/business/customer/register",
    meta: indexw7ZV3iZwBdMeta || {},
    alias: indexw7ZV3iZwBdMeta?.alias || [],
    redirect: indexw7ZV3iZwBdMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexw7ZV3iZwBdMeta?.name ?? "business-customer-register___ru",
    path: indexw7ZV3iZwBdMeta?.path ?? "/ru/business/customer/register",
    meta: indexw7ZV3iZwBdMeta || {},
    alias: indexw7ZV3iZwBdMeta?.alias || [],
    redirect: indexw7ZV3iZwBdMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/customer/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexjQ0wL5DR5zMeta?.name ?? "business___uk",
    path: indexjQ0wL5DR5zMeta?.path ?? "/business",
    meta: indexjQ0wL5DR5zMeta || {},
    alias: indexjQ0wL5DR5zMeta?.alias || [],
    redirect: indexjQ0wL5DR5zMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: indexjQ0wL5DR5zMeta?.name ?? "business___en",
    path: indexjQ0wL5DR5zMeta?.path ?? "/en/business",
    meta: indexjQ0wL5DR5zMeta || {},
    alias: indexjQ0wL5DR5zMeta?.alias || [],
    redirect: indexjQ0wL5DR5zMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: indexjQ0wL5DR5zMeta?.name ?? "business___es",
    path: indexjQ0wL5DR5zMeta?.path ?? "/es/business",
    meta: indexjQ0wL5DR5zMeta || {},
    alias: indexjQ0wL5DR5zMeta?.alias || [],
    redirect: indexjQ0wL5DR5zMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: indexjQ0wL5DR5zMeta?.name ?? "business___ru",
    path: indexjQ0wL5DR5zMeta?.path ?? "/ru/business",
    meta: indexjQ0wL5DR5zMeta || {},
    alias: indexjQ0wL5DR5zMeta?.alias || [],
    redirect: indexjQ0wL5DR5zMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/business/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93apbrwjeqmnMeta?.name ?? "cabinet-auth-email-confirm-request-token___uk",
    path: _91token_93apbrwjeqmnMeta?.path ?? "/cabinet/auth/email-confirm/:request()/:token()",
    meta: _91token_93apbrwjeqmnMeta || {},
    alias: _91token_93apbrwjeqmnMeta?.alias || [],
    redirect: _91token_93apbrwjeqmnMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93apbrwjeqmnMeta?.name ?? "cabinet-auth-email-confirm-request-token___en",
    path: _91token_93apbrwjeqmnMeta?.path ?? "/en/cabinet/auth/email-confirm/:request()/:token()",
    meta: _91token_93apbrwjeqmnMeta || {},
    alias: _91token_93apbrwjeqmnMeta?.alias || [],
    redirect: _91token_93apbrwjeqmnMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93apbrwjeqmnMeta?.name ?? "cabinet-auth-email-confirm-request-token___es",
    path: _91token_93apbrwjeqmnMeta?.path ?? "/es/cabinet/auth/email-confirm/:request()/:token()",
    meta: _91token_93apbrwjeqmnMeta || {},
    alias: _91token_93apbrwjeqmnMeta?.alias || [],
    redirect: _91token_93apbrwjeqmnMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93apbrwjeqmnMeta?.name ?? "cabinet-auth-email-confirm-request-token___ru",
    path: _91token_93apbrwjeqmnMeta?.path ?? "/ru/cabinet/auth/email-confirm/:request()/:token()",
    meta: _91token_93apbrwjeqmnMeta || {},
    alias: _91token_93apbrwjeqmnMeta?.alias || [],
    redirect: _91token_93apbrwjeqmnMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/email-confirm/[request]/[token].vue").then(m => m.default || m)
  },
  {
    name: indexhjsSujXxn7Meta?.name ?? "cabinet-auth-finish-registration___uk",
    path: indexhjsSujXxn7Meta?.path ?? "/cabinet/auth/finish-registration",
    meta: indexhjsSujXxn7Meta || {},
    alias: indexhjsSujXxn7Meta?.alias || [],
    redirect: indexhjsSujXxn7Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjsSujXxn7Meta?.name ?? "cabinet-auth-finish-registration___en",
    path: indexhjsSujXxn7Meta?.path ?? "/en/cabinet/auth/finish-registration",
    meta: indexhjsSujXxn7Meta || {},
    alias: indexhjsSujXxn7Meta?.alias || [],
    redirect: indexhjsSujXxn7Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjsSujXxn7Meta?.name ?? "cabinet-auth-finish-registration___es",
    path: indexhjsSujXxn7Meta?.path ?? "/es/cabinet/auth/finish-registration",
    meta: indexhjsSujXxn7Meta || {},
    alias: indexhjsSujXxn7Meta?.alias || [],
    redirect: indexhjsSujXxn7Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexhjsSujXxn7Meta?.name ?? "cabinet-auth-finish-registration___ru",
    path: indexhjsSujXxn7Meta?.path ?? "/ru/cabinet/auth/finish-registration",
    meta: indexhjsSujXxn7Meta || {},
    alias: indexhjsSujXxn7Meta?.alias || [],
    redirect: indexhjsSujXxn7Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/finish-registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_936MhlyScz2zMeta?.name ?? "cabinet-auth-reset-password-code___uk",
    path: _91code_936MhlyScz2zMeta?.path ?? "/cabinet/auth/reset-password/:code()",
    meta: _91code_936MhlyScz2zMeta || {},
    alias: _91code_936MhlyScz2zMeta?.alias || [],
    redirect: _91code_936MhlyScz2zMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_936MhlyScz2zMeta?.name ?? "cabinet-auth-reset-password-code___en",
    path: _91code_936MhlyScz2zMeta?.path ?? "/en/cabinet/auth/reset-password/:code()",
    meta: _91code_936MhlyScz2zMeta || {},
    alias: _91code_936MhlyScz2zMeta?.alias || [],
    redirect: _91code_936MhlyScz2zMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_936MhlyScz2zMeta?.name ?? "cabinet-auth-reset-password-code___es",
    path: _91code_936MhlyScz2zMeta?.path ?? "/es/cabinet/auth/reset-password/:code()",
    meta: _91code_936MhlyScz2zMeta || {},
    alias: _91code_936MhlyScz2zMeta?.alias || [],
    redirect: _91code_936MhlyScz2zMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_936MhlyScz2zMeta?.name ?? "cabinet-auth-reset-password-code___ru",
    path: _91code_936MhlyScz2zMeta?.path ?? "/ru/cabinet/auth/reset-password/:code()",
    meta: _91code_936MhlyScz2zMeta || {},
    alias: _91code_936MhlyScz2zMeta?.alias || [],
    redirect: _91code_936MhlyScz2zMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/[code].vue").then(m => m.default || m)
  },
  {
    name: index950K5k0DHGMeta?.name ?? "cabinet-auth-reset-password___uk",
    path: index950K5k0DHGMeta?.path ?? "/cabinet/auth/reset-password",
    meta: index950K5k0DHGMeta || {},
    alias: index950K5k0DHGMeta?.alias || [],
    redirect: index950K5k0DHGMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index950K5k0DHGMeta?.name ?? "cabinet-auth-reset-password___en",
    path: index950K5k0DHGMeta?.path ?? "/en/cabinet/auth/reset-password",
    meta: index950K5k0DHGMeta || {},
    alias: index950K5k0DHGMeta?.alias || [],
    redirect: index950K5k0DHGMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index950K5k0DHGMeta?.name ?? "cabinet-auth-reset-password___es",
    path: index950K5k0DHGMeta?.path ?? "/es/cabinet/auth/reset-password",
    meta: index950K5k0DHGMeta || {},
    alias: index950K5k0DHGMeta?.alias || [],
    redirect: index950K5k0DHGMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index950K5k0DHGMeta?.name ?? "cabinet-auth-reset-password___ru",
    path: index950K5k0DHGMeta?.path ?? "/ru/cabinet/auth/reset-password",
    meta: index950K5k0DHGMeta || {},
    alias: index950K5k0DHGMeta?.alias || [],
    redirect: index950K5k0DHGMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexuxIZCcDCljMeta?.name ?? "cabinet-auth-sign-in-flowId-magicToken___uk",
    path: indexuxIZCcDCljMeta?.path ?? "/cabinet/auth/sign-in/:flowId()/:magicToken()",
    meta: indexuxIZCcDCljMeta || {},
    alias: indexuxIZCcDCljMeta?.alias || [],
    redirect: indexuxIZCcDCljMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuxIZCcDCljMeta?.name ?? "cabinet-auth-sign-in-flowId-magicToken___en",
    path: indexuxIZCcDCljMeta?.path ?? "/en/cabinet/auth/sign-in/:flowId()/:magicToken()",
    meta: indexuxIZCcDCljMeta || {},
    alias: indexuxIZCcDCljMeta?.alias || [],
    redirect: indexuxIZCcDCljMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuxIZCcDCljMeta?.name ?? "cabinet-auth-sign-in-flowId-magicToken___es",
    path: indexuxIZCcDCljMeta?.path ?? "/es/cabinet/auth/sign-in/:flowId()/:magicToken()",
    meta: indexuxIZCcDCljMeta || {},
    alias: indexuxIZCcDCljMeta?.alias || [],
    redirect: indexuxIZCcDCljMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuxIZCcDCljMeta?.name ?? "cabinet-auth-sign-in-flowId-magicToken___ru",
    path: indexuxIZCcDCljMeta?.path ?? "/ru/cabinet/auth/sign-in/:flowId()/:magicToken()",
    meta: indexuxIZCcDCljMeta || {},
    alias: indexuxIZCcDCljMeta?.alias || [],
    redirect: indexuxIZCcDCljMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/[flowId]/[magicToken]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqkNzWNBKgcMeta?.name ?? "cabinet-auth-sign-in___uk",
    path: indexqkNzWNBKgcMeta?.path ?? "/cabinet/auth/sign-in",
    meta: indexqkNzWNBKgcMeta || {},
    alias: indexqkNzWNBKgcMeta?.alias || [],
    redirect: indexqkNzWNBKgcMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexqkNzWNBKgcMeta?.name ?? "cabinet-auth-sign-in___en",
    path: indexqkNzWNBKgcMeta?.path ?? "/en/cabinet/auth/sign-in",
    meta: indexqkNzWNBKgcMeta || {},
    alias: indexqkNzWNBKgcMeta?.alias || [],
    redirect: indexqkNzWNBKgcMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexqkNzWNBKgcMeta?.name ?? "cabinet-auth-sign-in___es",
    path: indexqkNzWNBKgcMeta?.path ?? "/es/cabinet/auth/sign-in",
    meta: indexqkNzWNBKgcMeta || {},
    alias: indexqkNzWNBKgcMeta?.alias || [],
    redirect: indexqkNzWNBKgcMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexqkNzWNBKgcMeta?.name ?? "cabinet-auth-sign-in___ru",
    path: indexqkNzWNBKgcMeta?.path ?? "/ru/cabinet/auth/sign-in",
    meta: indexqkNzWNBKgcMeta || {},
    alias: indexqkNzWNBKgcMeta?.alias || [],
    redirect: indexqkNzWNBKgcMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhQHxFTNhxMeta?.name ?? "cabinet-auth-sign-in-oauth___uk",
    path: indexjhQHxFTNhxMeta?.path ?? "/cabinet/auth/sign-in/oauth",
    meta: indexjhQHxFTNhxMeta || {},
    alias: indexjhQHxFTNhxMeta?.alias || [],
    redirect: indexjhQHxFTNhxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhQHxFTNhxMeta?.name ?? "cabinet-auth-sign-in-oauth___en",
    path: indexjhQHxFTNhxMeta?.path ?? "/en/cabinet/auth/sign-in/oauth",
    meta: indexjhQHxFTNhxMeta || {},
    alias: indexjhQHxFTNhxMeta?.alias || [],
    redirect: indexjhQHxFTNhxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhQHxFTNhxMeta?.name ?? "cabinet-auth-sign-in-oauth___es",
    path: indexjhQHxFTNhxMeta?.path ?? "/es/cabinet/auth/sign-in/oauth",
    meta: indexjhQHxFTNhxMeta || {},
    alias: indexjhQHxFTNhxMeta?.alias || [],
    redirect: indexjhQHxFTNhxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhQHxFTNhxMeta?.name ?? "cabinet-auth-sign-in-oauth___ru",
    path: indexjhQHxFTNhxMeta?.path ?? "/ru/cabinet/auth/sign-in/oauth",
    meta: indexjhQHxFTNhxMeta || {},
    alias: indexjhQHxFTNhxMeta?.alias || [],
    redirect: indexjhQHxFTNhxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-in/oauth/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45outFI17sBYw4bMeta?.name ?? "cabinet-auth-sign-out___uk",
    path: sign_45outFI17sBYw4bMeta?.path ?? "/cabinet/auth/sign-out",
    meta: sign_45outFI17sBYw4bMeta || {},
    alias: sign_45outFI17sBYw4bMeta?.alias || [],
    redirect: sign_45outFI17sBYw4bMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue").then(m => m.default || m)
  },
  {
    name: sign_45outFI17sBYw4bMeta?.name ?? "cabinet-auth-sign-out___en",
    path: sign_45outFI17sBYw4bMeta?.path ?? "/en/cabinet/auth/sign-out",
    meta: sign_45outFI17sBYw4bMeta || {},
    alias: sign_45outFI17sBYw4bMeta?.alias || [],
    redirect: sign_45outFI17sBYw4bMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue").then(m => m.default || m)
  },
  {
    name: sign_45outFI17sBYw4bMeta?.name ?? "cabinet-auth-sign-out___es",
    path: sign_45outFI17sBYw4bMeta?.path ?? "/es/cabinet/auth/sign-out",
    meta: sign_45outFI17sBYw4bMeta || {},
    alias: sign_45outFI17sBYw4bMeta?.alias || [],
    redirect: sign_45outFI17sBYw4bMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue").then(m => m.default || m)
  },
  {
    name: sign_45outFI17sBYw4bMeta?.name ?? "cabinet-auth-sign-out___ru",
    path: sign_45outFI17sBYw4bMeta?.path ?? "/ru/cabinet/auth/sign-out",
    meta: sign_45outFI17sBYw4bMeta || {},
    alias: sign_45outFI17sBYw4bMeta?.alias || [],
    redirect: sign_45outFI17sBYw4bMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-out.vue").then(m => m.default || m)
  },
  {
    name: indexqdT2VB2zXhMeta?.name ?? "cabinet-auth-sign-up___uk",
    path: indexqdT2VB2zXhMeta?.path ?? "/cabinet/auth/sign-up",
    meta: indexqdT2VB2zXhMeta || {},
    alias: indexqdT2VB2zXhMeta?.alias || [],
    redirect: indexqdT2VB2zXhMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdT2VB2zXhMeta?.name ?? "cabinet-auth-sign-up___en",
    path: indexqdT2VB2zXhMeta?.path ?? "/en/cabinet/auth/sign-up",
    meta: indexqdT2VB2zXhMeta || {},
    alias: indexqdT2VB2zXhMeta?.alias || [],
    redirect: indexqdT2VB2zXhMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdT2VB2zXhMeta?.name ?? "cabinet-auth-sign-up___es",
    path: indexqdT2VB2zXhMeta?.path ?? "/es/cabinet/auth/sign-up",
    meta: indexqdT2VB2zXhMeta || {},
    alias: indexqdT2VB2zXhMeta?.alias || [],
    redirect: indexqdT2VB2zXhMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: indexqdT2VB2zXhMeta?.name ?? "cabinet-auth-sign-up___ru",
    path: indexqdT2VB2zXhMeta?.path ?? "/ru/cabinet/auth/sign-up",
    meta: indexqdT2VB2zXhMeta || {},
    alias: indexqdT2VB2zXhMeta?.alias || [],
    redirect: indexqdT2VB2zXhMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/auth/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardDQduFRXX37Meta?.name ?? "cabinet-business-dashboard___uk",
    path: dashboardDQduFRXX37Meta?.path ?? "/cabinet/business/dashboard",
    meta: dashboardDQduFRXX37Meta || {},
    alias: dashboardDQduFRXX37Meta?.alias || [],
    redirect: dashboardDQduFRXX37Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardDQduFRXX37Meta?.name ?? "cabinet-business-dashboard___en",
    path: dashboardDQduFRXX37Meta?.path ?? "/en/cabinet/business/dashboard",
    meta: dashboardDQduFRXX37Meta || {},
    alias: dashboardDQduFRXX37Meta?.alias || [],
    redirect: dashboardDQduFRXX37Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardDQduFRXX37Meta?.name ?? "cabinet-business-dashboard___es",
    path: dashboardDQduFRXX37Meta?.path ?? "/es/cabinet/business/dashboard",
    meta: dashboardDQduFRXX37Meta || {},
    alias: dashboardDQduFRXX37Meta?.alias || [],
    redirect: dashboardDQduFRXX37Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardDQduFRXX37Meta?.name ?? "cabinet-business-dashboard___ru",
    path: dashboardDQduFRXX37Meta?.path ?? "/ru/cabinet/business/dashboard",
    meta: dashboardDQduFRXX37Meta || {},
    alias: dashboardDQduFRXX37Meta?.alias || [],
    redirect: dashboardDQduFRXX37Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/business/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexAcpFwjW6WMMeta?.name ?? "cabinet-calendar___uk",
    path: indexAcpFwjW6WMMeta?.path ?? "/cabinet/calendar",
    meta: indexAcpFwjW6WMMeta || {},
    alias: indexAcpFwjW6WMMeta?.alias || [],
    redirect: indexAcpFwjW6WMMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: indexAcpFwjW6WMMeta?.name ?? "cabinet-calendar___en",
    path: indexAcpFwjW6WMMeta?.path ?? "/en/cabinet/calendar",
    meta: indexAcpFwjW6WMMeta || {},
    alias: indexAcpFwjW6WMMeta?.alias || [],
    redirect: indexAcpFwjW6WMMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: indexAcpFwjW6WMMeta?.name ?? "cabinet-calendar___es",
    path: indexAcpFwjW6WMMeta?.path ?? "/es/cabinet/calendar",
    meta: indexAcpFwjW6WMMeta || {},
    alias: indexAcpFwjW6WMMeta?.alias || [],
    redirect: indexAcpFwjW6WMMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: indexAcpFwjW6WMMeta?.name ?? "cabinet-calendar___ru",
    path: indexAcpFwjW6WMMeta?.path ?? "/ru/cabinet/calendar",
    meta: indexAcpFwjW6WMMeta || {},
    alias: indexAcpFwjW6WMMeta?.alias || [],
    redirect: indexAcpFwjW6WMMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: indexw9xgASoMyPMeta?.name ?? "cabinet-chat-roomId___uk",
    path: indexw9xgASoMyPMeta?.path ?? "/cabinet/chat/:roomId()",
    meta: indexw9xgASoMyPMeta || {},
    alias: indexw9xgASoMyPMeta?.alias || [],
    redirect: indexw9xgASoMyPMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexw9xgASoMyPMeta?.name ?? "cabinet-chat-roomId___en",
    path: indexw9xgASoMyPMeta?.path ?? "/en/cabinet/chat/:roomId()",
    meta: indexw9xgASoMyPMeta || {},
    alias: indexw9xgASoMyPMeta?.alias || [],
    redirect: indexw9xgASoMyPMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexw9xgASoMyPMeta?.name ?? "cabinet-chat-roomId___es",
    path: indexw9xgASoMyPMeta?.path ?? "/es/cabinet/chat/:roomId()",
    meta: indexw9xgASoMyPMeta || {},
    alias: indexw9xgASoMyPMeta?.alias || [],
    redirect: indexw9xgASoMyPMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexw9xgASoMyPMeta?.name ?? "cabinet-chat-roomId___ru",
    path: indexw9xgASoMyPMeta?.path ?? "/ru/cabinet/chat/:roomId()",
    meta: indexw9xgASoMyPMeta || {},
    alias: indexw9xgASoMyPMeta?.alias || [],
    redirect: indexw9xgASoMyPMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexCQtCMgFsaaMeta?.name ?? "cabinet-chat___uk",
    path: indexCQtCMgFsaaMeta?.path ?? "/cabinet/chat",
    meta: indexCQtCMgFsaaMeta || {},
    alias: indexCQtCMgFsaaMeta?.alias || [],
    redirect: indexCQtCMgFsaaMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue").then(m => m.default || m)
  },
  {
    name: indexCQtCMgFsaaMeta?.name ?? "cabinet-chat___en",
    path: indexCQtCMgFsaaMeta?.path ?? "/en/cabinet/chat",
    meta: indexCQtCMgFsaaMeta || {},
    alias: indexCQtCMgFsaaMeta?.alias || [],
    redirect: indexCQtCMgFsaaMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue").then(m => m.default || m)
  },
  {
    name: indexCQtCMgFsaaMeta?.name ?? "cabinet-chat___es",
    path: indexCQtCMgFsaaMeta?.path ?? "/es/cabinet/chat",
    meta: indexCQtCMgFsaaMeta || {},
    alias: indexCQtCMgFsaaMeta?.alias || [],
    redirect: indexCQtCMgFsaaMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue").then(m => m.default || m)
  },
  {
    name: indexCQtCMgFsaaMeta?.name ?? "cabinet-chat___ru",
    path: indexCQtCMgFsaaMeta?.path ?? "/ru/cabinet/chat",
    meta: indexCQtCMgFsaaMeta || {},
    alias: indexCQtCMgFsaaMeta?.alias || [],
    redirect: indexCQtCMgFsaaMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/chat/index.vue").then(m => m.default || m)
  },
  {
    name: payIQATXowjWtMeta?.name ?? "cabinet-customer-carts-id-pay___uk",
    path: payIQATXowjWtMeta?.path ?? "/cabinet/customer/carts/:id()/pay",
    meta: payIQATXowjWtMeta || {},
    alias: payIQATXowjWtMeta?.alias || [],
    redirect: payIQATXowjWtMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: payIQATXowjWtMeta?.name ?? "cabinet-customer-carts-id-pay___en",
    path: payIQATXowjWtMeta?.path ?? "/en/cabinet/customer/carts/:id()/pay",
    meta: payIQATXowjWtMeta || {},
    alias: payIQATXowjWtMeta?.alias || [],
    redirect: payIQATXowjWtMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: payIQATXowjWtMeta?.name ?? "cabinet-customer-carts-id-pay___es",
    path: payIQATXowjWtMeta?.path ?? "/es/cabinet/customer/carts/:id()/pay",
    meta: payIQATXowjWtMeta || {},
    alias: payIQATXowjWtMeta?.alias || [],
    redirect: payIQATXowjWtMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: payIQATXowjWtMeta?.name ?? "cabinet-customer-carts-id-pay___ru",
    path: payIQATXowjWtMeta?.path ?? "/ru/cabinet/customer/carts/:id()/pay",
    meta: payIQATXowjWtMeta || {},
    alias: payIQATXowjWtMeta?.alias || [],
    redirect: payIQATXowjWtMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: checkout7ScQEVfVtxMeta?.name ?? "cabinet-customer-catalog-itemId-checkout___uk",
    path: checkout7ScQEVfVtxMeta?.path ?? "/cabinet/customer/catalog/:itemId()/checkout",
    meta: checkout7ScQEVfVtxMeta || {},
    alias: checkout7ScQEVfVtxMeta?.alias || [],
    redirect: checkout7ScQEVfVtxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout7ScQEVfVtxMeta?.name ?? "cabinet-customer-catalog-itemId-checkout___en",
    path: checkout7ScQEVfVtxMeta?.path ?? "/en/cabinet/customer/catalog/:itemId()/checkout",
    meta: checkout7ScQEVfVtxMeta || {},
    alias: checkout7ScQEVfVtxMeta?.alias || [],
    redirect: checkout7ScQEVfVtxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout7ScQEVfVtxMeta?.name ?? "cabinet-customer-catalog-itemId-checkout___es",
    path: checkout7ScQEVfVtxMeta?.path ?? "/es/cabinet/customer/catalog/:itemId()/checkout",
    meta: checkout7ScQEVfVtxMeta || {},
    alias: checkout7ScQEVfVtxMeta?.alias || [],
    redirect: checkout7ScQEVfVtxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout7ScQEVfVtxMeta?.name ?? "cabinet-customer-catalog-itemId-checkout___ru",
    path: checkout7ScQEVfVtxMeta?.path ?? "/ru/cabinet/customer/catalog/:itemId()/checkout",
    meta: checkout7ScQEVfVtxMeta || {},
    alias: checkout7ScQEVfVtxMeta?.alias || [],
    redirect: checkout7ScQEVfVtxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexa3c8kUjm3QMeta?.name ?? "cabinet-customer-catalog-itemId___uk",
    path: indexa3c8kUjm3QMeta?.path ?? "/cabinet/customer/catalog/:itemId()",
    meta: indexa3c8kUjm3QMeta || {},
    alias: indexa3c8kUjm3QMeta?.alias || [],
    redirect: indexa3c8kUjm3QMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa3c8kUjm3QMeta?.name ?? "cabinet-customer-catalog-itemId___en",
    path: indexa3c8kUjm3QMeta?.path ?? "/en/cabinet/customer/catalog/:itemId()",
    meta: indexa3c8kUjm3QMeta || {},
    alias: indexa3c8kUjm3QMeta?.alias || [],
    redirect: indexa3c8kUjm3QMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa3c8kUjm3QMeta?.name ?? "cabinet-customer-catalog-itemId___es",
    path: indexa3c8kUjm3QMeta?.path ?? "/es/cabinet/customer/catalog/:itemId()",
    meta: indexa3c8kUjm3QMeta || {},
    alias: indexa3c8kUjm3QMeta?.alias || [],
    redirect: indexa3c8kUjm3QMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa3c8kUjm3QMeta?.name ?? "cabinet-customer-catalog-itemId___ru",
    path: indexa3c8kUjm3QMeta?.path ?? "/ru/cabinet/customer/catalog/:itemId()",
    meta: indexa3c8kUjm3QMeta || {},
    alias: indexa3c8kUjm3QMeta?.alias || [],
    redirect: indexa3c8kUjm3QMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTC8lr75bZHMeta?.name ?? "cabinet-customer-catalog___uk",
    path: indexTC8lr75bZHMeta?.path ?? "/cabinet/customer/catalog",
    meta: indexTC8lr75bZHMeta || {},
    alias: indexTC8lr75bZHMeta?.alias || [],
    redirect: indexTC8lr75bZHMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexTC8lr75bZHMeta?.name ?? "cabinet-customer-catalog___en",
    path: indexTC8lr75bZHMeta?.path ?? "/en/cabinet/customer/catalog",
    meta: indexTC8lr75bZHMeta || {},
    alias: indexTC8lr75bZHMeta?.alias || [],
    redirect: indexTC8lr75bZHMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexTC8lr75bZHMeta?.name ?? "cabinet-customer-catalog___es",
    path: indexTC8lr75bZHMeta?.path ?? "/es/cabinet/customer/catalog",
    meta: indexTC8lr75bZHMeta || {},
    alias: indexTC8lr75bZHMeta?.alias || [],
    redirect: indexTC8lr75bZHMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexTC8lr75bZHMeta?.name ?? "cabinet-customer-catalog___ru",
    path: indexTC8lr75bZHMeta?.path ?? "/ru/cabinet/customer/catalog",
    meta: indexTC8lr75bZHMeta || {},
    alias: indexTC8lr75bZHMeta?.alias || [],
    redirect: indexTC8lr75bZHMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardfMpF22Dt0pMeta?.name ?? "cabinet-customer-dashboard___uk",
    path: dashboardfMpF22Dt0pMeta?.path ?? "/cabinet/customer/dashboard",
    meta: dashboardfMpF22Dt0pMeta || {},
    alias: dashboardfMpF22Dt0pMeta?.alias || [],
    redirect: dashboardfMpF22Dt0pMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardfMpF22Dt0pMeta?.name ?? "cabinet-customer-dashboard___en",
    path: dashboardfMpF22Dt0pMeta?.path ?? "/en/cabinet/customer/dashboard",
    meta: dashboardfMpF22Dt0pMeta || {},
    alias: dashboardfMpF22Dt0pMeta?.alias || [],
    redirect: dashboardfMpF22Dt0pMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardfMpF22Dt0pMeta?.name ?? "cabinet-customer-dashboard___es",
    path: dashboardfMpF22Dt0pMeta?.path ?? "/es/cabinet/customer/dashboard",
    meta: dashboardfMpF22Dt0pMeta || {},
    alias: dashboardfMpF22Dt0pMeta?.alias || [],
    redirect: dashboardfMpF22Dt0pMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardfMpF22Dt0pMeta?.name ?? "cabinet-customer-dashboard___ru",
    path: dashboardfMpF22Dt0pMeta?.path ?? "/ru/cabinet/customer/dashboard",
    meta: dashboardfMpF22Dt0pMeta || {},
    alias: dashboardfMpF22Dt0pMeta?.alias || [],
    redirect: dashboardfMpF22Dt0pMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: activationbtKGvXyKMEMeta?.name ?? "cabinet-employee-activation___uk",
    path: activationbtKGvXyKMEMeta?.path ?? "/cabinet/employee/activation",
    meta: activationbtKGvXyKMEMeta || {},
    alias: activationbtKGvXyKMEMeta?.alias || [],
    redirect: activationbtKGvXyKMEMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue").then(m => m.default || m)
  },
  {
    name: activationbtKGvXyKMEMeta?.name ?? "cabinet-employee-activation___en",
    path: activationbtKGvXyKMEMeta?.path ?? "/en/cabinet/employee/activation",
    meta: activationbtKGvXyKMEMeta || {},
    alias: activationbtKGvXyKMEMeta?.alias || [],
    redirect: activationbtKGvXyKMEMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue").then(m => m.default || m)
  },
  {
    name: activationbtKGvXyKMEMeta?.name ?? "cabinet-employee-activation___es",
    path: activationbtKGvXyKMEMeta?.path ?? "/es/cabinet/employee/activation",
    meta: activationbtKGvXyKMEMeta || {},
    alias: activationbtKGvXyKMEMeta?.alias || [],
    redirect: activationbtKGvXyKMEMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue").then(m => m.default || m)
  },
  {
    name: activationbtKGvXyKMEMeta?.name ?? "cabinet-employee-activation___ru",
    path: activationbtKGvXyKMEMeta?.path ?? "/ru/cabinet/employee/activation",
    meta: activationbtKGvXyKMEMeta || {},
    alias: activationbtKGvXyKMEMeta?.alias || [],
    redirect: activationbtKGvXyKMEMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/activation.vue").then(m => m.default || m)
  },
  {
    name: payeu8dMxqna0Meta?.name ?? "cabinet-employee-carts-id-pay___uk",
    path: payeu8dMxqna0Meta?.path ?? "/cabinet/employee/carts/:id()/pay",
    meta: payeu8dMxqna0Meta || {},
    alias: payeu8dMxqna0Meta?.alias || [],
    redirect: payeu8dMxqna0Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: payeu8dMxqna0Meta?.name ?? "cabinet-employee-carts-id-pay___en",
    path: payeu8dMxqna0Meta?.path ?? "/en/cabinet/employee/carts/:id()/pay",
    meta: payeu8dMxqna0Meta || {},
    alias: payeu8dMxqna0Meta?.alias || [],
    redirect: payeu8dMxqna0Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: payeu8dMxqna0Meta?.name ?? "cabinet-employee-carts-id-pay___es",
    path: payeu8dMxqna0Meta?.path ?? "/es/cabinet/employee/carts/:id()/pay",
    meta: payeu8dMxqna0Meta || {},
    alias: payeu8dMxqna0Meta?.alias || [],
    redirect: payeu8dMxqna0Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: payeu8dMxqna0Meta?.name ?? "cabinet-employee-carts-id-pay___ru",
    path: payeu8dMxqna0Meta?.path ?? "/ru/cabinet/employee/carts/:id()/pay",
    meta: payeu8dMxqna0Meta || {},
    alias: payeu8dMxqna0Meta?.alias || [],
    redirect: payeu8dMxqna0Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/carts/[id]/pay.vue").then(m => m.default || m)
  },
  {
    name: checkout5bal2VtYqeMeta?.name ?? "cabinet-employee-catalog-itemId-checkout___uk",
    path: checkout5bal2VtYqeMeta?.path ?? "/cabinet/employee/catalog/:itemId()/checkout",
    meta: checkout5bal2VtYqeMeta || {},
    alias: checkout5bal2VtYqeMeta?.alias || [],
    redirect: checkout5bal2VtYqeMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout5bal2VtYqeMeta?.name ?? "cabinet-employee-catalog-itemId-checkout___en",
    path: checkout5bal2VtYqeMeta?.path ?? "/en/cabinet/employee/catalog/:itemId()/checkout",
    meta: checkout5bal2VtYqeMeta || {},
    alias: checkout5bal2VtYqeMeta?.alias || [],
    redirect: checkout5bal2VtYqeMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout5bal2VtYqeMeta?.name ?? "cabinet-employee-catalog-itemId-checkout___es",
    path: checkout5bal2VtYqeMeta?.path ?? "/es/cabinet/employee/catalog/:itemId()/checkout",
    meta: checkout5bal2VtYqeMeta || {},
    alias: checkout5bal2VtYqeMeta?.alias || [],
    redirect: checkout5bal2VtYqeMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkout5bal2VtYqeMeta?.name ?? "cabinet-employee-catalog-itemId-checkout___ru",
    path: checkout5bal2VtYqeMeta?.path ?? "/ru/cabinet/employee/catalog/:itemId()/checkout",
    meta: checkout5bal2VtYqeMeta || {},
    alias: checkout5bal2VtYqeMeta?.alias || [],
    redirect: checkout5bal2VtYqeMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexyUhCLKJqkAMeta?.name ?? "cabinet-employee-catalog-itemId___uk",
    path: indexyUhCLKJqkAMeta?.path ?? "/cabinet/employee/catalog/:itemId()",
    meta: indexyUhCLKJqkAMeta || {},
    alias: indexyUhCLKJqkAMeta?.alias || [],
    redirect: indexyUhCLKJqkAMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyUhCLKJqkAMeta?.name ?? "cabinet-employee-catalog-itemId___en",
    path: indexyUhCLKJqkAMeta?.path ?? "/en/cabinet/employee/catalog/:itemId()",
    meta: indexyUhCLKJqkAMeta || {},
    alias: indexyUhCLKJqkAMeta?.alias || [],
    redirect: indexyUhCLKJqkAMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyUhCLKJqkAMeta?.name ?? "cabinet-employee-catalog-itemId___es",
    path: indexyUhCLKJqkAMeta?.path ?? "/es/cabinet/employee/catalog/:itemId()",
    meta: indexyUhCLKJqkAMeta || {},
    alias: indexyUhCLKJqkAMeta?.alias || [],
    redirect: indexyUhCLKJqkAMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyUhCLKJqkAMeta?.name ?? "cabinet-employee-catalog-itemId___ru",
    path: indexyUhCLKJqkAMeta?.path ?? "/ru/cabinet/employee/catalog/:itemId()",
    meta: indexyUhCLKJqkAMeta || {},
    alias: indexyUhCLKJqkAMeta?.alias || [],
    redirect: indexyUhCLKJqkAMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/[itemId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrAZMFHCNIQMeta?.name ?? "cabinet-employee-catalog___uk",
    path: indexrAZMFHCNIQMeta?.path ?? "/cabinet/employee/catalog",
    meta: indexrAZMFHCNIQMeta || {},
    alias: indexrAZMFHCNIQMeta?.alias || [],
    redirect: indexrAZMFHCNIQMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexrAZMFHCNIQMeta?.name ?? "cabinet-employee-catalog___en",
    path: indexrAZMFHCNIQMeta?.path ?? "/en/cabinet/employee/catalog",
    meta: indexrAZMFHCNIQMeta || {},
    alias: indexrAZMFHCNIQMeta?.alias || [],
    redirect: indexrAZMFHCNIQMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexrAZMFHCNIQMeta?.name ?? "cabinet-employee-catalog___es",
    path: indexrAZMFHCNIQMeta?.path ?? "/es/cabinet/employee/catalog",
    meta: indexrAZMFHCNIQMeta || {},
    alias: indexrAZMFHCNIQMeta?.alias || [],
    redirect: indexrAZMFHCNIQMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexrAZMFHCNIQMeta?.name ?? "cabinet-employee-catalog___ru",
    path: indexrAZMFHCNIQMeta?.path ?? "/ru/cabinet/employee/catalog",
    meta: indexrAZMFHCNIQMeta || {},
    alias: indexrAZMFHCNIQMeta?.alias || [],
    redirect: indexrAZMFHCNIQMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardfzm70OO0oNMeta?.name ?? "cabinet-employee-dashboard___uk",
    path: dashboardfzm70OO0oNMeta?.path ?? "/cabinet/employee/dashboard",
    meta: dashboardfzm70OO0oNMeta || {},
    alias: dashboardfzm70OO0oNMeta?.alias || [],
    redirect: dashboardfzm70OO0oNMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardfzm70OO0oNMeta?.name ?? "cabinet-employee-dashboard___en",
    path: dashboardfzm70OO0oNMeta?.path ?? "/en/cabinet/employee/dashboard",
    meta: dashboardfzm70OO0oNMeta || {},
    alias: dashboardfzm70OO0oNMeta?.alias || [],
    redirect: dashboardfzm70OO0oNMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardfzm70OO0oNMeta?.name ?? "cabinet-employee-dashboard___es",
    path: dashboardfzm70OO0oNMeta?.path ?? "/es/cabinet/employee/dashboard",
    meta: dashboardfzm70OO0oNMeta || {},
    alias: dashboardfzm70OO0oNMeta?.alias || [],
    redirect: dashboardfzm70OO0oNMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardfzm70OO0oNMeta?.name ?? "cabinet-employee-dashboard___ru",
    path: dashboardfzm70OO0oNMeta?.path ?? "/ru/cabinet/employee/dashboard",
    meta: dashboardfzm70OO0oNMeta || {},
    alias: dashboardfzm70OO0oNMeta?.alias || [],
    redirect: dashboardfzm70OO0oNMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/employee/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexnk30GuiXlkMeta?.name ?? "cabinet-meetings-meetingId___uk",
    path: indexnk30GuiXlkMeta?.path ?? "/cabinet/meetings/:meetingId()",
    meta: indexnk30GuiXlkMeta || {},
    alias: indexnk30GuiXlkMeta?.alias || [],
    redirect: indexnk30GuiXlkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnk30GuiXlkMeta?.name ?? "cabinet-meetings-meetingId___en",
    path: indexnk30GuiXlkMeta?.path ?? "/en/cabinet/meetings/:meetingId()",
    meta: indexnk30GuiXlkMeta || {},
    alias: indexnk30GuiXlkMeta?.alias || [],
    redirect: indexnk30GuiXlkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnk30GuiXlkMeta?.name ?? "cabinet-meetings-meetingId___es",
    path: indexnk30GuiXlkMeta?.path ?? "/es/cabinet/meetings/:meetingId()",
    meta: indexnk30GuiXlkMeta || {},
    alias: indexnk30GuiXlkMeta?.alias || [],
    redirect: indexnk30GuiXlkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnk30GuiXlkMeta?.name ?? "cabinet-meetings-meetingId___ru",
    path: indexnk30GuiXlkMeta?.path ?? "/ru/cabinet/meetings/:meetingId()",
    meta: indexnk30GuiXlkMeta || {},
    alias: indexnk30GuiXlkMeta?.alias || [],
    redirect: indexnk30GuiXlkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/index.vue").then(m => m.default || m)
  },
  {
    name: room1VrmeeYJMaMeta?.name ?? "cabinet-meetings-meetingId-room___uk",
    path: room1VrmeeYJMaMeta?.path ?? "/cabinet/meetings/:meetingId()/room",
    meta: room1VrmeeYJMaMeta || {},
    alias: room1VrmeeYJMaMeta?.alias || [],
    redirect: room1VrmeeYJMaMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue").then(m => m.default || m)
  },
  {
    name: room1VrmeeYJMaMeta?.name ?? "cabinet-meetings-meetingId-room___en",
    path: room1VrmeeYJMaMeta?.path ?? "/en/cabinet/meetings/:meetingId()/room",
    meta: room1VrmeeYJMaMeta || {},
    alias: room1VrmeeYJMaMeta?.alias || [],
    redirect: room1VrmeeYJMaMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue").then(m => m.default || m)
  },
  {
    name: room1VrmeeYJMaMeta?.name ?? "cabinet-meetings-meetingId-room___es",
    path: room1VrmeeYJMaMeta?.path ?? "/es/cabinet/meetings/:meetingId()/room",
    meta: room1VrmeeYJMaMeta || {},
    alias: room1VrmeeYJMaMeta?.alias || [],
    redirect: room1VrmeeYJMaMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue").then(m => m.default || m)
  },
  {
    name: room1VrmeeYJMaMeta?.name ?? "cabinet-meetings-meetingId-room___ru",
    path: room1VrmeeYJMaMeta?.path ?? "/ru/cabinet/meetings/:meetingId()/room",
    meta: room1VrmeeYJMaMeta || {},
    alias: room1VrmeeYJMaMeta?.alias || [],
    redirect: room1VrmeeYJMaMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/meetings/[meetingId]/room.vue").then(m => m.default || m)
  },
  {
    name: confirmzrKuDy5VspMeta?.name ?? "cabinet-orders-orderId-confirm___uk",
    path: confirmzrKuDy5VspMeta?.path ?? "/cabinet/orders/:orderId()/confirm",
    meta: confirmzrKuDy5VspMeta || {},
    alias: confirmzrKuDy5VspMeta?.alias || [],
    redirect: confirmzrKuDy5VspMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmzrKuDy5VspMeta?.name ?? "cabinet-orders-orderId-confirm___en",
    path: confirmzrKuDy5VspMeta?.path ?? "/en/cabinet/orders/:orderId()/confirm",
    meta: confirmzrKuDy5VspMeta || {},
    alias: confirmzrKuDy5VspMeta?.alias || [],
    redirect: confirmzrKuDy5VspMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmzrKuDy5VspMeta?.name ?? "cabinet-orders-orderId-confirm___es",
    path: confirmzrKuDy5VspMeta?.path ?? "/es/cabinet/orders/:orderId()/confirm",
    meta: confirmzrKuDy5VspMeta || {},
    alias: confirmzrKuDy5VspMeta?.alias || [],
    redirect: confirmzrKuDy5VspMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmzrKuDy5VspMeta?.name ?? "cabinet-orders-orderId-confirm___ru",
    path: confirmzrKuDy5VspMeta?.path ?? "/ru/cabinet/orders/:orderId()/confirm",
    meta: confirmzrKuDy5VspMeta || {},
    alias: confirmzrKuDy5VspMeta?.alias || [],
    redirect: confirmzrKuDy5VspMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/[orderId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: rescheduley0Xik6hLmOMeta?.name ?? "cabinet-orders-sessions-sessionId-reschedule___uk",
    path: rescheduley0Xik6hLmOMeta?.path ?? "/cabinet/orders/sessions/:sessionId()/reschedule",
    meta: rescheduley0Xik6hLmOMeta || {},
    alias: rescheduley0Xik6hLmOMeta?.alias || [],
    redirect: rescheduley0Xik6hLmOMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue").then(m => m.default || m)
  },
  {
    name: rescheduley0Xik6hLmOMeta?.name ?? "cabinet-orders-sessions-sessionId-reschedule___en",
    path: rescheduley0Xik6hLmOMeta?.path ?? "/en/cabinet/orders/sessions/:sessionId()/reschedule",
    meta: rescheduley0Xik6hLmOMeta || {},
    alias: rescheduley0Xik6hLmOMeta?.alias || [],
    redirect: rescheduley0Xik6hLmOMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue").then(m => m.default || m)
  },
  {
    name: rescheduley0Xik6hLmOMeta?.name ?? "cabinet-orders-sessions-sessionId-reschedule___es",
    path: rescheduley0Xik6hLmOMeta?.path ?? "/es/cabinet/orders/sessions/:sessionId()/reschedule",
    meta: rescheduley0Xik6hLmOMeta || {},
    alias: rescheduley0Xik6hLmOMeta?.alias || [],
    redirect: rescheduley0Xik6hLmOMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue").then(m => m.default || m)
  },
  {
    name: rescheduley0Xik6hLmOMeta?.name ?? "cabinet-orders-sessions-sessionId-reschedule___ru",
    path: rescheduley0Xik6hLmOMeta?.path ?? "/ru/cabinet/orders/sessions/:sessionId()/reschedule",
    meta: rescheduley0Xik6hLmOMeta || {},
    alias: rescheduley0Xik6hLmOMeta?.alias || [],
    redirect: rescheduley0Xik6hLmOMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/reschedule.vue").then(m => m.default || m)
  },
  {
    name: reviewegon8COXRrMeta?.name ?? "cabinet-orders-sessions-sessionId-review___uk",
    path: reviewegon8COXRrMeta?.path ?? "/cabinet/orders/sessions/:sessionId()/review",
    meta: reviewegon8COXRrMeta || {},
    alias: reviewegon8COXRrMeta?.alias || [],
    redirect: reviewegon8COXRrMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue").then(m => m.default || m)
  },
  {
    name: reviewegon8COXRrMeta?.name ?? "cabinet-orders-sessions-sessionId-review___en",
    path: reviewegon8COXRrMeta?.path ?? "/en/cabinet/orders/sessions/:sessionId()/review",
    meta: reviewegon8COXRrMeta || {},
    alias: reviewegon8COXRrMeta?.alias || [],
    redirect: reviewegon8COXRrMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue").then(m => m.default || m)
  },
  {
    name: reviewegon8COXRrMeta?.name ?? "cabinet-orders-sessions-sessionId-review___es",
    path: reviewegon8COXRrMeta?.path ?? "/es/cabinet/orders/sessions/:sessionId()/review",
    meta: reviewegon8COXRrMeta || {},
    alias: reviewegon8COXRrMeta?.alias || [],
    redirect: reviewegon8COXRrMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue").then(m => m.default || m)
  },
  {
    name: reviewegon8COXRrMeta?.name ?? "cabinet-orders-sessions-sessionId-review___ru",
    path: reviewegon8COXRrMeta?.path ?? "/ru/cabinet/orders/sessions/:sessionId()/review",
    meta: reviewegon8COXRrMeta || {},
    alias: reviewegon8COXRrMeta?.alias || [],
    redirect: reviewegon8COXRrMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/orders/sessions/[sessionId]/review.vue").then(m => m.default || m)
  },
  {
    name: calendar8vL0a7KBzwMeta?.name ?? "cabinet-psychologist-calendar___uk",
    path: calendar8vL0a7KBzwMeta?.path ?? "/cabinet/psychologist/calendar",
    meta: calendar8vL0a7KBzwMeta || {},
    alias: calendar8vL0a7KBzwMeta?.alias || [],
    redirect: calendar8vL0a7KBzwMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/calendar.vue").then(m => m.default || m)
  },
  {
    name: calendar8vL0a7KBzwMeta?.name ?? "cabinet-psychologist-calendar___en",
    path: calendar8vL0a7KBzwMeta?.path ?? "/en/cabinet/psychologist/calendar",
    meta: calendar8vL0a7KBzwMeta || {},
    alias: calendar8vL0a7KBzwMeta?.alias || [],
    redirect: calendar8vL0a7KBzwMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/calendar.vue").then(m => m.default || m)
  },
  {
    name: calendar8vL0a7KBzwMeta?.name ?? "cabinet-psychologist-calendar___es",
    path: calendar8vL0a7KBzwMeta?.path ?? "/es/cabinet/psychologist/calendar",
    meta: calendar8vL0a7KBzwMeta || {},
    alias: calendar8vL0a7KBzwMeta?.alias || [],
    redirect: calendar8vL0a7KBzwMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/calendar.vue").then(m => m.default || m)
  },
  {
    name: calendar8vL0a7KBzwMeta?.name ?? "cabinet-psychologist-calendar___ru",
    path: calendar8vL0a7KBzwMeta?.path ?? "/ru/cabinet/psychologist/calendar",
    meta: calendar8vL0a7KBzwMeta || {},
    alias: calendar8vL0a7KBzwMeta?.alias || [],
    redirect: calendar8vL0a7KBzwMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/calendar.vue").then(m => m.default || m)
  },
  {
    name: dashboardgjXAAV0QxcMeta?.name ?? "cabinet-psychologist-dashboard___uk",
    path: dashboardgjXAAV0QxcMeta?.path ?? "/cabinet/psychologist/dashboard",
    meta: dashboardgjXAAV0QxcMeta || {},
    alias: dashboardgjXAAV0QxcMeta?.alias || [],
    redirect: dashboardgjXAAV0QxcMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardgjXAAV0QxcMeta?.name ?? "cabinet-psychologist-dashboard___en",
    path: dashboardgjXAAV0QxcMeta?.path ?? "/en/cabinet/psychologist/dashboard",
    meta: dashboardgjXAAV0QxcMeta || {},
    alias: dashboardgjXAAV0QxcMeta?.alias || [],
    redirect: dashboardgjXAAV0QxcMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardgjXAAV0QxcMeta?.name ?? "cabinet-psychologist-dashboard___es",
    path: dashboardgjXAAV0QxcMeta?.path ?? "/es/cabinet/psychologist/dashboard",
    meta: dashboardgjXAAV0QxcMeta || {},
    alias: dashboardgjXAAV0QxcMeta?.alias || [],
    redirect: dashboardgjXAAV0QxcMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardgjXAAV0QxcMeta?.name ?? "cabinet-psychologist-dashboard___ru",
    path: dashboardgjXAAV0QxcMeta?.path ?? "/ru/cabinet/psychologist/dashboard",
    meta: dashboardgjXAAV0QxcMeta || {},
    alias: dashboardgjXAAV0QxcMeta?.alias || [],
    redirect: dashboardgjXAAV0QxcMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexbKrxEjr0WoMeta?.name ?? "cabinet-psychologist-google-calendars___uk",
    path: indexbKrxEjr0WoMeta?.path ?? "/cabinet/psychologist/google-calendars",
    meta: indexbKrxEjr0WoMeta || {},
    alias: indexbKrxEjr0WoMeta?.alias || [],
    redirect: indexbKrxEjr0WoMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKrxEjr0WoMeta?.name ?? "cabinet-psychologist-google-calendars___en",
    path: indexbKrxEjr0WoMeta?.path ?? "/en/cabinet/psychologist/google-calendars",
    meta: indexbKrxEjr0WoMeta || {},
    alias: indexbKrxEjr0WoMeta?.alias || [],
    redirect: indexbKrxEjr0WoMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKrxEjr0WoMeta?.name ?? "cabinet-psychologist-google-calendars___es",
    path: indexbKrxEjr0WoMeta?.path ?? "/es/cabinet/psychologist/google-calendars",
    meta: indexbKrxEjr0WoMeta || {},
    alias: indexbKrxEjr0WoMeta?.alias || [],
    redirect: indexbKrxEjr0WoMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKrxEjr0WoMeta?.name ?? "cabinet-psychologist-google-calendars___ru",
    path: indexbKrxEjr0WoMeta?.path ?? "/ru/cabinet/psychologist/google-calendars",
    meta: indexbKrxEjr0WoMeta || {},
    alias: indexbKrxEjr0WoMeta?.alias || [],
    redirect: indexbKrxEjr0WoMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/google-calendars/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5pJTer7uKMeta?.name ?? "cabinet-psychologist-my-customers___uk",
    path: indexM5pJTer7uKMeta?.path ?? "/cabinet/psychologist/my-customers",
    meta: indexM5pJTer7uKMeta || {},
    alias: indexM5pJTer7uKMeta?.alias || [],
    redirect: indexM5pJTer7uKMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5pJTer7uKMeta?.name ?? "cabinet-psychologist-my-customers___en",
    path: indexM5pJTer7uKMeta?.path ?? "/en/cabinet/psychologist/my-customers",
    meta: indexM5pJTer7uKMeta || {},
    alias: indexM5pJTer7uKMeta?.alias || [],
    redirect: indexM5pJTer7uKMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5pJTer7uKMeta?.name ?? "cabinet-psychologist-my-customers___es",
    path: indexM5pJTer7uKMeta?.path ?? "/es/cabinet/psychologist/my-customers",
    meta: indexM5pJTer7uKMeta || {},
    alias: indexM5pJTer7uKMeta?.alias || [],
    redirect: indexM5pJTer7uKMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexM5pJTer7uKMeta?.name ?? "cabinet-psychologist-my-customers___ru",
    path: indexM5pJTer7uKMeta?.path ?? "/ru/cabinet/psychologist/my-customers",
    meta: indexM5pJTer7uKMeta || {},
    alias: indexM5pJTer7uKMeta?.alias || [],
    redirect: indexM5pJTer7uKMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/my-customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexgYNnwH9mhrMeta?.name ?? "cabinet-psychologist-payment-methods-add-method___uk",
    path: indexgYNnwH9mhrMeta?.path ?? "/cabinet/psychologist/payment-methods/add/:method()",
    meta: indexgYNnwH9mhrMeta || {},
    alias: indexgYNnwH9mhrMeta?.alias || [],
    redirect: indexgYNnwH9mhrMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgYNnwH9mhrMeta?.name ?? "cabinet-psychologist-payment-methods-add-method___en",
    path: indexgYNnwH9mhrMeta?.path ?? "/en/cabinet/psychologist/payment-methods/add/:method()",
    meta: indexgYNnwH9mhrMeta || {},
    alias: indexgYNnwH9mhrMeta?.alias || [],
    redirect: indexgYNnwH9mhrMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgYNnwH9mhrMeta?.name ?? "cabinet-psychologist-payment-methods-add-method___es",
    path: indexgYNnwH9mhrMeta?.path ?? "/es/cabinet/psychologist/payment-methods/add/:method()",
    meta: indexgYNnwH9mhrMeta || {},
    alias: indexgYNnwH9mhrMeta?.alias || [],
    redirect: indexgYNnwH9mhrMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgYNnwH9mhrMeta?.name ?? "cabinet-psychologist-payment-methods-add-method___ru",
    path: indexgYNnwH9mhrMeta?.path ?? "/ru/cabinet/psychologist/payment-methods/add/:method()",
    meta: indexgYNnwH9mhrMeta || {},
    alias: indexgYNnwH9mhrMeta?.alias || [],
    redirect: indexgYNnwH9mhrMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payment-methods/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: indexearmxnSW74Meta?.name ?? "cabinet-psychologist-payouts-add-method___uk",
    path: indexearmxnSW74Meta?.path ?? "/cabinet/psychologist/payouts/add/:method()",
    meta: indexearmxnSW74Meta || {},
    alias: indexearmxnSW74Meta?.alias || [],
    redirect: indexearmxnSW74Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: indexearmxnSW74Meta?.name ?? "cabinet-psychologist-payouts-add-method___en",
    path: indexearmxnSW74Meta?.path ?? "/en/cabinet/psychologist/payouts/add/:method()",
    meta: indexearmxnSW74Meta || {},
    alias: indexearmxnSW74Meta?.alias || [],
    redirect: indexearmxnSW74Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: indexearmxnSW74Meta?.name ?? "cabinet-psychologist-payouts-add-method___es",
    path: indexearmxnSW74Meta?.path ?? "/es/cabinet/psychologist/payouts/add/:method()",
    meta: indexearmxnSW74Meta || {},
    alias: indexearmxnSW74Meta?.alias || [],
    redirect: indexearmxnSW74Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: indexearmxnSW74Meta?.name ?? "cabinet-psychologist-payouts-add-method___ru",
    path: indexearmxnSW74Meta?.path ?? "/ru/cabinet/psychologist/payouts/add/:method()",
    meta: indexearmxnSW74Meta || {},
    alias: indexearmxnSW74Meta?.alias || [],
    redirect: indexearmxnSW74Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/add/[method]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrANlcaGzIkMeta?.name ?? "cabinet-psychologist-payouts___uk",
    path: indexrANlcaGzIkMeta?.path ?? "/cabinet/psychologist/payouts",
    meta: indexrANlcaGzIkMeta || {},
    alias: indexrANlcaGzIkMeta?.alias || [],
    redirect: indexrANlcaGzIkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexrANlcaGzIkMeta?.name ?? "cabinet-psychologist-payouts___en",
    path: indexrANlcaGzIkMeta?.path ?? "/en/cabinet/psychologist/payouts",
    meta: indexrANlcaGzIkMeta || {},
    alias: indexrANlcaGzIkMeta?.alias || [],
    redirect: indexrANlcaGzIkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexrANlcaGzIkMeta?.name ?? "cabinet-psychologist-payouts___es",
    path: indexrANlcaGzIkMeta?.path ?? "/es/cabinet/psychologist/payouts",
    meta: indexrANlcaGzIkMeta || {},
    alias: indexrANlcaGzIkMeta?.alias || [],
    redirect: indexrANlcaGzIkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexrANlcaGzIkMeta?.name ?? "cabinet-psychologist-payouts___ru",
    path: indexrANlcaGzIkMeta?.path ?? "/ru/cabinet/psychologist/payouts",
    meta: indexrANlcaGzIkMeta || {},
    alias: indexrANlcaGzIkMeta?.alias || [],
    redirect: indexrANlcaGzIkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexTE0oIYlTfIMeta?.name ?? "cabinet-psychologist-profile___uk",
    path: indexTE0oIYlTfIMeta?.path ?? "/cabinet/psychologist/profile",
    meta: indexTE0oIYlTfIMeta || {},
    alias: indexTE0oIYlTfIMeta?.alias || [],
    redirect: indexTE0oIYlTfIMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexTE0oIYlTfIMeta?.name ?? "cabinet-psychologist-profile___en",
    path: indexTE0oIYlTfIMeta?.path ?? "/en/cabinet/psychologist/profile",
    meta: indexTE0oIYlTfIMeta || {},
    alias: indexTE0oIYlTfIMeta?.alias || [],
    redirect: indexTE0oIYlTfIMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexTE0oIYlTfIMeta?.name ?? "cabinet-psychologist-profile___es",
    path: indexTE0oIYlTfIMeta?.path ?? "/es/cabinet/psychologist/profile",
    meta: indexTE0oIYlTfIMeta || {},
    alias: indexTE0oIYlTfIMeta?.alias || [],
    redirect: indexTE0oIYlTfIMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexTE0oIYlTfIMeta?.name ?? "cabinet-psychologist-profile___ru",
    path: indexTE0oIYlTfIMeta?.path ?? "/ru/cabinet/psychologist/profile",
    meta: indexTE0oIYlTfIMeta || {},
    alias: indexTE0oIYlTfIMeta?.alias || [],
    redirect: indexTE0oIYlTfIMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexqT9fQ4biE7Meta?.name ?? "cabinet-psychologist-suggestions-chat-roomId___uk",
    path: indexqT9fQ4biE7Meta?.path ?? "/cabinet/psychologist/suggestions/chat/:roomId()",
    meta: indexqT9fQ4biE7Meta || {},
    alias: indexqT9fQ4biE7Meta?.alias || [],
    redirect: indexqT9fQ4biE7Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqT9fQ4biE7Meta?.name ?? "cabinet-psychologist-suggestions-chat-roomId___en",
    path: indexqT9fQ4biE7Meta?.path ?? "/en/cabinet/psychologist/suggestions/chat/:roomId()",
    meta: indexqT9fQ4biE7Meta || {},
    alias: indexqT9fQ4biE7Meta?.alias || [],
    redirect: indexqT9fQ4biE7Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqT9fQ4biE7Meta?.name ?? "cabinet-psychologist-suggestions-chat-roomId___es",
    path: indexqT9fQ4biE7Meta?.path ?? "/es/cabinet/psychologist/suggestions/chat/:roomId()",
    meta: indexqT9fQ4biE7Meta || {},
    alias: indexqT9fQ4biE7Meta?.alias || [],
    redirect: indexqT9fQ4biE7Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqT9fQ4biE7Meta?.name ?? "cabinet-psychologist-suggestions-chat-roomId___ru",
    path: indexqT9fQ4biE7Meta?.path ?? "/ru/cabinet/psychologist/suggestions/chat/:roomId()",
    meta: indexqT9fQ4biE7Meta || {},
    alias: indexqT9fQ4biE7Meta?.alias || [],
    redirect: indexqT9fQ4biE7Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/psychologist/suggestions/chat/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKdqP0kfeJ1Meta?.name ?? "cabinet-settings___uk",
    path: indexKdqP0kfeJ1Meta?.path ?? "/cabinet/settings",
    meta: indexKdqP0kfeJ1Meta || {},
    alias: indexKdqP0kfeJ1Meta?.alias || [],
    redirect: indexKdqP0kfeJ1Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexKdqP0kfeJ1Meta?.name ?? "cabinet-settings___en",
    path: indexKdqP0kfeJ1Meta?.path ?? "/en/cabinet/settings",
    meta: indexKdqP0kfeJ1Meta || {},
    alias: indexKdqP0kfeJ1Meta?.alias || [],
    redirect: indexKdqP0kfeJ1Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexKdqP0kfeJ1Meta?.name ?? "cabinet-settings___es",
    path: indexKdqP0kfeJ1Meta?.path ?? "/es/cabinet/settings",
    meta: indexKdqP0kfeJ1Meta || {},
    alias: indexKdqP0kfeJ1Meta?.alias || [],
    redirect: indexKdqP0kfeJ1Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexKdqP0kfeJ1Meta?.name ?? "cabinet-settings___ru",
    path: indexKdqP0kfeJ1Meta?.path ?? "/ru/cabinet/settings",
    meta: indexKdqP0kfeJ1Meta || {},
    alias: indexKdqP0kfeJ1Meta?.alias || [],
    redirect: indexKdqP0kfeJ1Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/cabinet/settings/index.vue").then(m => m.default || m)
  },
  {
    name: index9ZDfzXUde1Meta?.name ?? "contacts___uk",
    path: index9ZDfzXUde1Meta?.path ?? "/contacts",
    meta: index9ZDfzXUde1Meta || {},
    alias: index9ZDfzXUde1Meta?.alias || [],
    redirect: index9ZDfzXUde1Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: index9ZDfzXUde1Meta?.name ?? "contacts___en",
    path: index9ZDfzXUde1Meta?.path ?? "/en/contacts",
    meta: index9ZDfzXUde1Meta || {},
    alias: index9ZDfzXUde1Meta?.alias || [],
    redirect: index9ZDfzXUde1Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: index9ZDfzXUde1Meta?.name ?? "contacts___es",
    path: index9ZDfzXUde1Meta?.path ?? "/es/contacts",
    meta: index9ZDfzXUde1Meta || {},
    alias: index9ZDfzXUde1Meta?.alias || [],
    redirect: index9ZDfzXUde1Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: index9ZDfzXUde1Meta?.name ?? "contacts___ru",
    path: index9ZDfzXUde1Meta?.path ?? "/ru/contacts",
    meta: index9ZDfzXUde1Meta || {},
    alias: index9ZDfzXUde1Meta?.alias || [],
    redirect: index9ZDfzXUde1Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexUDKTAnHz0RMeta?.name ?? "emergency___uk",
    path: indexUDKTAnHz0RMeta?.path ?? "/emergency",
    meta: indexUDKTAnHz0RMeta || {},
    alias: indexUDKTAnHz0RMeta?.alias || [],
    redirect: indexUDKTAnHz0RMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/emergency/index.vue").then(m => m.default || m)
  },
  {
    name: indexUDKTAnHz0RMeta?.name ?? "emergency___en",
    path: indexUDKTAnHz0RMeta?.path ?? "/en/emergency",
    meta: indexUDKTAnHz0RMeta || {},
    alias: indexUDKTAnHz0RMeta?.alias || [],
    redirect: indexUDKTAnHz0RMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/emergency/index.vue").then(m => m.default || m)
  },
  {
    name: indexUDKTAnHz0RMeta?.name ?? "emergency___es",
    path: indexUDKTAnHz0RMeta?.path ?? "/es/emergency",
    meta: indexUDKTAnHz0RMeta || {},
    alias: indexUDKTAnHz0RMeta?.alias || [],
    redirect: indexUDKTAnHz0RMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/emergency/index.vue").then(m => m.default || m)
  },
  {
    name: indexUDKTAnHz0RMeta?.name ?? "emergency___ru",
    path: indexUDKTAnHz0RMeta?.path ?? "/ru/emergency",
    meta: indexUDKTAnHz0RMeta || {},
    alias: indexUDKTAnHz0RMeta?.alias || [],
    redirect: indexUDKTAnHz0RMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/emergency/index.vue").then(m => m.default || m)
  },
  {
    name: indexDQzhMz3lQPMeta?.name ?? "family___uk",
    path: indexDQzhMz3lQPMeta?.path ?? "/family",
    meta: indexDQzhMz3lQPMeta || {},
    alias: indexDQzhMz3lQPMeta?.alias || [],
    redirect: indexDQzhMz3lQPMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/family/index.vue").then(m => m.default || m)
  },
  {
    name: indexDQzhMz3lQPMeta?.name ?? "family___en",
    path: indexDQzhMz3lQPMeta?.path ?? "/en/family",
    meta: indexDQzhMz3lQPMeta || {},
    alias: indexDQzhMz3lQPMeta?.alias || [],
    redirect: indexDQzhMz3lQPMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/family/index.vue").then(m => m.default || m)
  },
  {
    name: indexDQzhMz3lQPMeta?.name ?? "family___es",
    path: indexDQzhMz3lQPMeta?.path ?? "/es/family",
    meta: indexDQzhMz3lQPMeta || {},
    alias: indexDQzhMz3lQPMeta?.alias || [],
    redirect: indexDQzhMz3lQPMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/family/index.vue").then(m => m.default || m)
  },
  {
    name: indexDQzhMz3lQPMeta?.name ?? "family___ru",
    path: indexDQzhMz3lQPMeta?.path ?? "/ru/family",
    meta: indexDQzhMz3lQPMeta || {},
    alias: indexDQzhMz3lQPMeta?.alias || [],
    redirect: indexDQzhMz3lQPMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/family/index.vue").then(m => m.default || m)
  },
  {
    name: index3kNQOa4RUqMeta?.name ?? "for-psychologists___uk",
    path: index3kNQOa4RUqMeta?.path ?? "/for-psychologists",
    meta: index3kNQOa4RUqMeta || {},
    alias: index3kNQOa4RUqMeta?.alias || [],
    redirect: index3kNQOa4RUqMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue").then(m => m.default || m)
  },
  {
    name: index3kNQOa4RUqMeta?.name ?? "for-psychologists___en",
    path: index3kNQOa4RUqMeta?.path ?? "/en/for-psychologists",
    meta: index3kNQOa4RUqMeta || {},
    alias: index3kNQOa4RUqMeta?.alias || [],
    redirect: index3kNQOa4RUqMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue").then(m => m.default || m)
  },
  {
    name: index3kNQOa4RUqMeta?.name ?? "for-psychologists___es",
    path: index3kNQOa4RUqMeta?.path ?? "/es/for-psychologists",
    meta: index3kNQOa4RUqMeta || {},
    alias: index3kNQOa4RUqMeta?.alias || [],
    redirect: index3kNQOa4RUqMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue").then(m => m.default || m)
  },
  {
    name: index3kNQOa4RUqMeta?.name ?? "for-psychologists___ru",
    path: index3kNQOa4RUqMeta?.path ?? "/ru/for-psychologists",
    meta: index3kNQOa4RUqMeta || {},
    alias: index3kNQOa4RUqMeta?.alias || [],
    redirect: index3kNQOa4RUqMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/for-psychologists/index.vue").then(m => m.default || m)
  },
  {
    name: indexXoOQI5DaMsMeta?.name ?? "index___uk",
    path: indexXoOQI5DaMsMeta?.path ?? "/",
    meta: indexXoOQI5DaMsMeta || {},
    alias: indexXoOQI5DaMsMeta?.alias || [],
    redirect: indexXoOQI5DaMsMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexXoOQI5DaMsMeta?.name ?? "index___en",
    path: indexXoOQI5DaMsMeta?.path ?? "/en",
    meta: indexXoOQI5DaMsMeta || {},
    alias: indexXoOQI5DaMsMeta?.alias || [],
    redirect: indexXoOQI5DaMsMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexXoOQI5DaMsMeta?.name ?? "index___es",
    path: indexXoOQI5DaMsMeta?.path ?? "/es",
    meta: indexXoOQI5DaMsMeta || {},
    alias: indexXoOQI5DaMsMeta?.alias || [],
    redirect: indexXoOQI5DaMsMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexXoOQI5DaMsMeta?.name ?? "index___ru",
    path: indexXoOQI5DaMsMeta?.path ?? "/ru",
    meta: indexXoOQI5DaMsMeta || {},
    alias: indexXoOQI5DaMsMeta?.alias || [],
    redirect: indexXoOQI5DaMsMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexITWAFCLP3hMeta?.name ?? "invoices-id___uk",
    path: indexITWAFCLP3hMeta?.path ?? "/invoices/:id()",
    meta: indexITWAFCLP3hMeta || {},
    alias: indexITWAFCLP3hMeta?.alias || [],
    redirect: indexITWAFCLP3hMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexITWAFCLP3hMeta?.name ?? "invoices-id___en",
    path: indexITWAFCLP3hMeta?.path ?? "/en/invoices/:id()",
    meta: indexITWAFCLP3hMeta || {},
    alias: indexITWAFCLP3hMeta?.alias || [],
    redirect: indexITWAFCLP3hMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexITWAFCLP3hMeta?.name ?? "invoices-id___es",
    path: indexITWAFCLP3hMeta?.path ?? "/es/invoices/:id()",
    meta: indexITWAFCLP3hMeta || {},
    alias: indexITWAFCLP3hMeta?.alias || [],
    redirect: indexITWAFCLP3hMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexITWAFCLP3hMeta?.name ?? "invoices-id___ru",
    path: indexITWAFCLP3hMeta?.path ?? "/ru/invoices/:id()",
    meta: indexITWAFCLP3hMeta || {},
    alias: indexITWAFCLP3hMeta?.alias || [],
    redirect: indexITWAFCLP3hMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexE5E35YQf7QMeta?.name ?? "kids___uk",
    path: indexE5E35YQf7QMeta?.path ?? "/kids",
    meta: indexE5E35YQf7QMeta || {},
    alias: indexE5E35YQf7QMeta?.alias || [],
    redirect: indexE5E35YQf7QMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: indexE5E35YQf7QMeta?.name ?? "kids___en",
    path: indexE5E35YQf7QMeta?.path ?? "/en/kids",
    meta: indexE5E35YQf7QMeta || {},
    alias: indexE5E35YQf7QMeta?.alias || [],
    redirect: indexE5E35YQf7QMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: indexE5E35YQf7QMeta?.name ?? "kids___es",
    path: indexE5E35YQf7QMeta?.path ?? "/es/kids",
    meta: indexE5E35YQf7QMeta || {},
    alias: indexE5E35YQf7QMeta?.alias || [],
    redirect: indexE5E35YQf7QMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: indexE5E35YQf7QMeta?.name ?? "kids___ru",
    path: indexE5E35YQf7QMeta?.path ?? "/ru/kids",
    meta: indexE5E35YQf7QMeta || {},
    alias: indexE5E35YQf7QMeta?.alias || [],
    redirect: indexE5E35YQf7QMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/kids/index.vue").then(m => m.default || m)
  },
  {
    name: bookingBfCXOC7Uv3Meta?.name ?? "matching-id-booking___uk",
    path: bookingBfCXOC7Uv3Meta?.path ?? "/matching/:id()/booking",
    meta: bookingBfCXOC7Uv3Meta || {},
    alias: bookingBfCXOC7Uv3Meta?.alias || [],
    redirect: bookingBfCXOC7Uv3Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: bookingBfCXOC7Uv3Meta?.name ?? "matching-id-booking___en",
    path: bookingBfCXOC7Uv3Meta?.path ?? "/en/matching/:id()/booking",
    meta: bookingBfCXOC7Uv3Meta || {},
    alias: bookingBfCXOC7Uv3Meta?.alias || [],
    redirect: bookingBfCXOC7Uv3Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: bookingBfCXOC7Uv3Meta?.name ?? "matching-id-booking___es",
    path: bookingBfCXOC7Uv3Meta?.path ?? "/es/matching/:id()/booking",
    meta: bookingBfCXOC7Uv3Meta || {},
    alias: bookingBfCXOC7Uv3Meta?.alias || [],
    redirect: bookingBfCXOC7Uv3Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: bookingBfCXOC7Uv3Meta?.name ?? "matching-id-booking___ru",
    path: bookingBfCXOC7Uv3Meta?.path ?? "/ru/matching/:id()/booking",
    meta: bookingBfCXOC7Uv3Meta || {},
    alias: bookingBfCXOC7Uv3Meta?.alias || [],
    redirect: bookingBfCXOC7Uv3Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: category8bqVYRZoNEMeta?.name ?? "matching-id-category___uk",
    path: category8bqVYRZoNEMeta?.path ?? "/matching/:id()/category",
    meta: category8bqVYRZoNEMeta || {},
    alias: category8bqVYRZoNEMeta?.alias || [],
    redirect: category8bqVYRZoNEMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue").then(m => m.default || m)
  },
  {
    name: category8bqVYRZoNEMeta?.name ?? "matching-id-category___en",
    path: category8bqVYRZoNEMeta?.path ?? "/en/matching/:id()/category",
    meta: category8bqVYRZoNEMeta || {},
    alias: category8bqVYRZoNEMeta?.alias || [],
    redirect: category8bqVYRZoNEMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue").then(m => m.default || m)
  },
  {
    name: category8bqVYRZoNEMeta?.name ?? "matching-id-category___es",
    path: category8bqVYRZoNEMeta?.path ?? "/es/matching/:id()/category",
    meta: category8bqVYRZoNEMeta || {},
    alias: category8bqVYRZoNEMeta?.alias || [],
    redirect: category8bqVYRZoNEMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue").then(m => m.default || m)
  },
  {
    name: category8bqVYRZoNEMeta?.name ?? "matching-id-category___ru",
    path: category8bqVYRZoNEMeta?.path ?? "/ru/matching/:id()/category",
    meta: category8bqVYRZoNEMeta || {},
    alias: category8bqVYRZoNEMeta?.alias || [],
    redirect: category8bqVYRZoNEMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/category.vue").then(m => m.default || m)
  },
  {
    name: childhood_ageQI7UyFsaMBMeta?.name ?? "matching-id-childhood_age___uk",
    path: childhood_ageQI7UyFsaMBMeta?.path ?? "/matching/:id()/childhood_age",
    meta: childhood_ageQI7UyFsaMBMeta || {},
    alias: childhood_ageQI7UyFsaMBMeta?.alias || [],
    redirect: childhood_ageQI7UyFsaMBMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue").then(m => m.default || m)
  },
  {
    name: childhood_ageQI7UyFsaMBMeta?.name ?? "matching-id-childhood_age___en",
    path: childhood_ageQI7UyFsaMBMeta?.path ?? "/en/matching/:id()/childhood_age",
    meta: childhood_ageQI7UyFsaMBMeta || {},
    alias: childhood_ageQI7UyFsaMBMeta?.alias || [],
    redirect: childhood_ageQI7UyFsaMBMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue").then(m => m.default || m)
  },
  {
    name: childhood_ageQI7UyFsaMBMeta?.name ?? "matching-id-childhood_age___es",
    path: childhood_ageQI7UyFsaMBMeta?.path ?? "/es/matching/:id()/childhood_age",
    meta: childhood_ageQI7UyFsaMBMeta || {},
    alias: childhood_ageQI7UyFsaMBMeta?.alias || [],
    redirect: childhood_ageQI7UyFsaMBMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue").then(m => m.default || m)
  },
  {
    name: childhood_ageQI7UyFsaMBMeta?.name ?? "matching-id-childhood_age___ru",
    path: childhood_ageQI7UyFsaMBMeta?.path ?? "/ru/matching/:id()/childhood_age",
    meta: childhood_ageQI7UyFsaMBMeta || {},
    alias: childhood_ageQI7UyFsaMBMeta?.alias || [],
    redirect: childhood_ageQI7UyFsaMBMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/childhood_age.vue").then(m => m.default || m)
  },
  {
    name: finishedVMKbZ5UomkMeta?.name ?? "matching-id-finished___uk",
    path: finishedVMKbZ5UomkMeta?.path ?? "/matching/:id()/finished",
    meta: finishedVMKbZ5UomkMeta || {},
    alias: finishedVMKbZ5UomkMeta?.alias || [],
    redirect: finishedVMKbZ5UomkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue").then(m => m.default || m)
  },
  {
    name: finishedVMKbZ5UomkMeta?.name ?? "matching-id-finished___en",
    path: finishedVMKbZ5UomkMeta?.path ?? "/en/matching/:id()/finished",
    meta: finishedVMKbZ5UomkMeta || {},
    alias: finishedVMKbZ5UomkMeta?.alias || [],
    redirect: finishedVMKbZ5UomkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue").then(m => m.default || m)
  },
  {
    name: finishedVMKbZ5UomkMeta?.name ?? "matching-id-finished___es",
    path: finishedVMKbZ5UomkMeta?.path ?? "/es/matching/:id()/finished",
    meta: finishedVMKbZ5UomkMeta || {},
    alias: finishedVMKbZ5UomkMeta?.alias || [],
    redirect: finishedVMKbZ5UomkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue").then(m => m.default || m)
  },
  {
    name: finishedVMKbZ5UomkMeta?.name ?? "matching-id-finished___ru",
    path: finishedVMKbZ5UomkMeta?.path ?? "/ru/matching/:id()/finished",
    meta: finishedVMKbZ5UomkMeta || {},
    alias: finishedVMKbZ5UomkMeta?.alias || [],
    redirect: finishedVMKbZ5UomkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/finished.vue").then(m => m.default || m)
  },
  {
    name: initialGRVIvrzbpHMeta?.name ?? "matching-id-initial___uk",
    path: initialGRVIvrzbpHMeta?.path ?? "/matching/:id()/initial",
    meta: initialGRVIvrzbpHMeta || {},
    alias: initialGRVIvrzbpHMeta?.alias || [],
    redirect: initialGRVIvrzbpHMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue").then(m => m.default || m)
  },
  {
    name: initialGRVIvrzbpHMeta?.name ?? "matching-id-initial___en",
    path: initialGRVIvrzbpHMeta?.path ?? "/en/matching/:id()/initial",
    meta: initialGRVIvrzbpHMeta || {},
    alias: initialGRVIvrzbpHMeta?.alias || [],
    redirect: initialGRVIvrzbpHMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue").then(m => m.default || m)
  },
  {
    name: initialGRVIvrzbpHMeta?.name ?? "matching-id-initial___es",
    path: initialGRVIvrzbpHMeta?.path ?? "/es/matching/:id()/initial",
    meta: initialGRVIvrzbpHMeta || {},
    alias: initialGRVIvrzbpHMeta?.alias || [],
    redirect: initialGRVIvrzbpHMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue").then(m => m.default || m)
  },
  {
    name: initialGRVIvrzbpHMeta?.name ?? "matching-id-initial___ru",
    path: initialGRVIvrzbpHMeta?.path ?? "/ru/matching/:id()/initial",
    meta: initialGRVIvrzbpHMeta || {},
    alias: initialGRVIvrzbpHMeta?.alias || [],
    redirect: initialGRVIvrzbpHMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/initial.vue").then(m => m.default || m)
  },
  {
    name: psychologisteL3jaEITLpMeta?.name ?? "matching-id-psychologist___uk",
    path: psychologisteL3jaEITLpMeta?.path ?? "/matching/:id()/psychologist",
    meta: psychologisteL3jaEITLpMeta || {},
    alias: psychologisteL3jaEITLpMeta?.alias || [],
    redirect: psychologisteL3jaEITLpMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue").then(m => m.default || m)
  },
  {
    name: psychologisteL3jaEITLpMeta?.name ?? "matching-id-psychologist___en",
    path: psychologisteL3jaEITLpMeta?.path ?? "/en/matching/:id()/psychologist",
    meta: psychologisteL3jaEITLpMeta || {},
    alias: psychologisteL3jaEITLpMeta?.alias || [],
    redirect: psychologisteL3jaEITLpMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue").then(m => m.default || m)
  },
  {
    name: psychologisteL3jaEITLpMeta?.name ?? "matching-id-psychologist___es",
    path: psychologisteL3jaEITLpMeta?.path ?? "/es/matching/:id()/psychologist",
    meta: psychologisteL3jaEITLpMeta || {},
    alias: psychologisteL3jaEITLpMeta?.alias || [],
    redirect: psychologisteL3jaEITLpMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue").then(m => m.default || m)
  },
  {
    name: psychologisteL3jaEITLpMeta?.name ?? "matching-id-psychologist___ru",
    path: psychologisteL3jaEITLpMeta?.path ?? "/ru/matching/:id()/psychologist",
    meta: psychologisteL3jaEITLpMeta || {},
    alias: psychologisteL3jaEITLpMeta?.alias || [],
    redirect: psychologisteL3jaEITLpMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/psychologist.vue").then(m => m.default || m)
  },
  {
    name: requestsRV80EPlnslMeta?.name ?? "matching-id-requests___uk",
    path: requestsRV80EPlnslMeta?.path ?? "/matching/:id()/requests",
    meta: requestsRV80EPlnslMeta || {},
    alias: requestsRV80EPlnslMeta?.alias || [],
    redirect: requestsRV80EPlnslMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue").then(m => m.default || m)
  },
  {
    name: requestsRV80EPlnslMeta?.name ?? "matching-id-requests___en",
    path: requestsRV80EPlnslMeta?.path ?? "/en/matching/:id()/requests",
    meta: requestsRV80EPlnslMeta || {},
    alias: requestsRV80EPlnslMeta?.alias || [],
    redirect: requestsRV80EPlnslMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue").then(m => m.default || m)
  },
  {
    name: requestsRV80EPlnslMeta?.name ?? "matching-id-requests___es",
    path: requestsRV80EPlnslMeta?.path ?? "/es/matching/:id()/requests",
    meta: requestsRV80EPlnslMeta || {},
    alias: requestsRV80EPlnslMeta?.alias || [],
    redirect: requestsRV80EPlnslMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue").then(m => m.default || m)
  },
  {
    name: requestsRV80EPlnslMeta?.name ?? "matching-id-requests___ru",
    path: requestsRV80EPlnslMeta?.path ?? "/ru/matching/:id()/requests",
    meta: requestsRV80EPlnslMeta || {},
    alias: requestsRV80EPlnslMeta?.alias || [],
    redirect: requestsRV80EPlnslMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/[id]/requests.vue").then(m => m.default || m)
  },
  {
    name: indexkpu9osV6TLMeta?.name ?? "matching___uk",
    path: indexkpu9osV6TLMeta?.path ?? "/matching",
    meta: indexkpu9osV6TLMeta || {},
    alias: indexkpu9osV6TLMeta?.alias || [],
    redirect: indexkpu9osV6TLMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/index.vue").then(m => m.default || m)
  },
  {
    name: indexkpu9osV6TLMeta?.name ?? "matching___en",
    path: indexkpu9osV6TLMeta?.path ?? "/en/matching",
    meta: indexkpu9osV6TLMeta || {},
    alias: indexkpu9osV6TLMeta?.alias || [],
    redirect: indexkpu9osV6TLMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/index.vue").then(m => m.default || m)
  },
  {
    name: indexkpu9osV6TLMeta?.name ?? "matching___es",
    path: indexkpu9osV6TLMeta?.path ?? "/es/matching",
    meta: indexkpu9osV6TLMeta || {},
    alias: indexkpu9osV6TLMeta?.alias || [],
    redirect: indexkpu9osV6TLMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/index.vue").then(m => m.default || m)
  },
  {
    name: indexkpu9osV6TLMeta?.name ?? "matching___ru",
    path: indexkpu9osV6TLMeta?.path ?? "/ru/matching",
    meta: indexkpu9osV6TLMeta || {},
    alias: indexkpu9osV6TLMeta?.alias || [],
    redirect: indexkpu9osV6TLMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/matching/index.vue").then(m => m.default || m)
  },
  {
    name: successicrXSmHQ0iMeta?.name ?? "orders-orderId-success___uk",
    path: successicrXSmHQ0iMeta?.path ?? "/orders/:orderId()/success",
    meta: successicrXSmHQ0iMeta || {},
    alias: successicrXSmHQ0iMeta?.alias || [],
    redirect: successicrXSmHQ0iMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue").then(m => m.default || m)
  },
  {
    name: successicrXSmHQ0iMeta?.name ?? "orders-orderId-success___en",
    path: successicrXSmHQ0iMeta?.path ?? "/en/orders/:orderId()/success",
    meta: successicrXSmHQ0iMeta || {},
    alias: successicrXSmHQ0iMeta?.alias || [],
    redirect: successicrXSmHQ0iMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue").then(m => m.default || m)
  },
  {
    name: successicrXSmHQ0iMeta?.name ?? "orders-orderId-success___es",
    path: successicrXSmHQ0iMeta?.path ?? "/es/orders/:orderId()/success",
    meta: successicrXSmHQ0iMeta || {},
    alias: successicrXSmHQ0iMeta?.alias || [],
    redirect: successicrXSmHQ0iMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue").then(m => m.default || m)
  },
  {
    name: successicrXSmHQ0iMeta?.name ?? "orders-orderId-success___ru",
    path: successicrXSmHQ0iMeta?.path ?? "/ru/orders/:orderId()/success",
    meta: successicrXSmHQ0iMeta || {},
    alias: successicrXSmHQ0iMeta?.alias || [],
    redirect: successicrXSmHQ0iMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/orders/[orderId]/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GwtDJylv5IMeta?.name ?? "pages-slug___uk",
    path: _91slug_93GwtDJylv5IMeta?.path ?? "/pages/:slug()",
    meta: _91slug_93GwtDJylv5IMeta || {},
    alias: _91slug_93GwtDJylv5IMeta?.alias || [],
    redirect: _91slug_93GwtDJylv5IMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GwtDJylv5IMeta?.name ?? "pages-slug___en",
    path: _91slug_93GwtDJylv5IMeta?.path ?? "/en/pages/:slug()",
    meta: _91slug_93GwtDJylv5IMeta || {},
    alias: _91slug_93GwtDJylv5IMeta?.alias || [],
    redirect: _91slug_93GwtDJylv5IMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GwtDJylv5IMeta?.name ?? "pages-slug___es",
    path: _91slug_93GwtDJylv5IMeta?.path ?? "/es/pages/:slug()",
    meta: _91slug_93GwtDJylv5IMeta || {},
    alias: _91slug_93GwtDJylv5IMeta?.alias || [],
    redirect: _91slug_93GwtDJylv5IMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GwtDJylv5IMeta?.name ?? "pages-slug___ru",
    path: _91slug_93GwtDJylv5IMeta?.path ?? "/ru/pages/:slug()",
    meta: _91slug_93GwtDJylv5IMeta || {},
    alias: _91slug_93GwtDJylv5IMeta?.alias || [],
    redirect: _91slug_93GwtDJylv5IMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: tech_45problems4auPv3oAUCMeta?.name ?? "pages-tech-problems___uk",
    path: tech_45problems4auPv3oAUCMeta?.path ?? "/pages/tech-problems",
    meta: tech_45problems4auPv3oAUCMeta || {},
    alias: tech_45problems4auPv3oAUCMeta?.alias || [],
    redirect: tech_45problems4auPv3oAUCMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue").then(m => m.default || m)
  },
  {
    name: tech_45problems4auPv3oAUCMeta?.name ?? "pages-tech-problems___en",
    path: tech_45problems4auPv3oAUCMeta?.path ?? "/en/pages/tech-problems",
    meta: tech_45problems4auPv3oAUCMeta || {},
    alias: tech_45problems4auPv3oAUCMeta?.alias || [],
    redirect: tech_45problems4auPv3oAUCMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue").then(m => m.default || m)
  },
  {
    name: tech_45problems4auPv3oAUCMeta?.name ?? "pages-tech-problems___es",
    path: tech_45problems4auPv3oAUCMeta?.path ?? "/es/pages/tech-problems",
    meta: tech_45problems4auPv3oAUCMeta || {},
    alias: tech_45problems4auPv3oAUCMeta?.alias || [],
    redirect: tech_45problems4auPv3oAUCMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue").then(m => m.default || m)
  },
  {
    name: tech_45problems4auPv3oAUCMeta?.name ?? "pages-tech-problems___ru",
    path: tech_45problems4auPv3oAUCMeta?.path ?? "/ru/pages/tech-problems",
    meta: tech_45problems4auPv3oAUCMeta || {},
    alias: tech_45problems4auPv3oAUCMeta?.alias || [],
    redirect: tech_45problems4auPv3oAUCMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pages/tech-problems.vue").then(m => m.default || m)
  },
  {
    name: _91payload_93FlG44H0AgkMeta?.name ?? "pay-liqpay-payload___uk",
    path: _91payload_93FlG44H0AgkMeta?.path ?? "/pay/liqpay/:payload()",
    meta: _91payload_93FlG44H0AgkMeta || {},
    alias: _91payload_93FlG44H0AgkMeta?.alias || [],
    redirect: _91payload_93FlG44H0AgkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: _91payload_93FlG44H0AgkMeta?.name ?? "pay-liqpay-payload___en",
    path: _91payload_93FlG44H0AgkMeta?.path ?? "/en/pay/liqpay/:payload()",
    meta: _91payload_93FlG44H0AgkMeta || {},
    alias: _91payload_93FlG44H0AgkMeta?.alias || [],
    redirect: _91payload_93FlG44H0AgkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: _91payload_93FlG44H0AgkMeta?.name ?? "pay-liqpay-payload___es",
    path: _91payload_93FlG44H0AgkMeta?.path ?? "/es/pay/liqpay/:payload()",
    meta: _91payload_93FlG44H0AgkMeta || {},
    alias: _91payload_93FlG44H0AgkMeta?.alias || [],
    redirect: _91payload_93FlG44H0AgkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: _91payload_93FlG44H0AgkMeta?.name ?? "pay-liqpay-payload___ru",
    path: _91payload_93FlG44H0AgkMeta?.path ?? "/ru/pay/liqpay/:payload()",
    meta: _91payload_93FlG44H0AgkMeta || {},
    alias: _91payload_93FlG44H0AgkMeta?.alias || [],
    redirect: _91payload_93FlG44H0AgkMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/liqpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: _91payload_93EGN54YiNlKMeta?.name ?? "pay-wayforpay-payload___uk",
    path: _91payload_93EGN54YiNlKMeta?.path ?? "/pay/wayforpay/:payload()",
    meta: _91payload_93EGN54YiNlKMeta || {},
    alias: _91payload_93EGN54YiNlKMeta?.alias || [],
    redirect: _91payload_93EGN54YiNlKMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: _91payload_93EGN54YiNlKMeta?.name ?? "pay-wayforpay-payload___en",
    path: _91payload_93EGN54YiNlKMeta?.path ?? "/en/pay/wayforpay/:payload()",
    meta: _91payload_93EGN54YiNlKMeta || {},
    alias: _91payload_93EGN54YiNlKMeta?.alias || [],
    redirect: _91payload_93EGN54YiNlKMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: _91payload_93EGN54YiNlKMeta?.name ?? "pay-wayforpay-payload___es",
    path: _91payload_93EGN54YiNlKMeta?.path ?? "/es/pay/wayforpay/:payload()",
    meta: _91payload_93EGN54YiNlKMeta || {},
    alias: _91payload_93EGN54YiNlKMeta?.alias || [],
    redirect: _91payload_93EGN54YiNlKMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: _91payload_93EGN54YiNlKMeta?.name ?? "pay-wayforpay-payload___ru",
    path: _91payload_93EGN54YiNlKMeta?.path ?? "/ru/pay/wayforpay/:payload()",
    meta: _91payload_93EGN54YiNlKMeta || {},
    alias: _91payload_93EGN54YiNlKMeta?.alias || [],
    redirect: _91payload_93EGN54YiNlKMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/pay/wayforpay/[payload].vue").then(m => m.default || m)
  },
  {
    name: index0LW3gAcR7MMeta?.name ?? "psychologists-filter___uk",
    path: index0LW3gAcR7MMeta?.path ?? "/psychologists/:filter(.*)*",
    meta: index0LW3gAcR7MMeta || {},
    alias: index0LW3gAcR7MMeta?.alias || [],
    redirect: index0LW3gAcR7MMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue").then(m => m.default || m)
  },
  {
    name: index0LW3gAcR7MMeta?.name ?? "psychologists-filter___en",
    path: index0LW3gAcR7MMeta?.path ?? "/en/psychologists/:filter(.*)*",
    meta: index0LW3gAcR7MMeta || {},
    alias: index0LW3gAcR7MMeta?.alias || [],
    redirect: index0LW3gAcR7MMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue").then(m => m.default || m)
  },
  {
    name: index0LW3gAcR7MMeta?.name ?? "psychologists-filter___es",
    path: index0LW3gAcR7MMeta?.path ?? "/es/psychologists/:filter(.*)*",
    meta: index0LW3gAcR7MMeta || {},
    alias: index0LW3gAcR7MMeta?.alias || [],
    redirect: index0LW3gAcR7MMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue").then(m => m.default || m)
  },
  {
    name: index0LW3gAcR7MMeta?.name ?? "psychologists-filter___ru",
    path: index0LW3gAcR7MMeta?.path ?? "/ru/psychologists/:filter(.*)*",
    meta: index0LW3gAcR7MMeta || {},
    alias: index0LW3gAcR7MMeta?.alias || [],
    redirect: index0LW3gAcR7MMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/[...filter]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGPaXbRFSUxMeta?.name ?? "psychologists-checkout-slug___uk",
    path: indexGPaXbRFSUxMeta?.path ?? "/psychologists/checkout/:slug()",
    meta: indexGPaXbRFSUxMeta || {},
    alias: indexGPaXbRFSUxMeta?.alias || [],
    redirect: indexGPaXbRFSUxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGPaXbRFSUxMeta?.name ?? "psychologists-checkout-slug___en",
    path: indexGPaXbRFSUxMeta?.path ?? "/en/psychologists/checkout/:slug()",
    meta: indexGPaXbRFSUxMeta || {},
    alias: indexGPaXbRFSUxMeta?.alias || [],
    redirect: indexGPaXbRFSUxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGPaXbRFSUxMeta?.name ?? "psychologists-checkout-slug___es",
    path: indexGPaXbRFSUxMeta?.path ?? "/es/psychologists/checkout/:slug()",
    meta: indexGPaXbRFSUxMeta || {},
    alias: indexGPaXbRFSUxMeta?.alias || [],
    redirect: indexGPaXbRFSUxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGPaXbRFSUxMeta?.name ?? "psychologists-checkout-slug___ru",
    path: indexGPaXbRFSUxMeta?.path ?? "/ru/psychologists/checkout/:slug()",
    meta: indexGPaXbRFSUxMeta || {},
    alias: indexGPaXbRFSUxMeta?.alias || [],
    redirect: indexGPaXbRFSUxMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: registeruNpdRfmGrmMeta?.name ?? "psychologists-checkout-slug-register___uk",
    path: registeruNpdRfmGrmMeta?.path ?? "/psychologists/checkout/:slug()/register",
    meta: registeruNpdRfmGrmMeta || {},
    alias: registeruNpdRfmGrmMeta?.alias || [],
    redirect: registeruNpdRfmGrmMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue").then(m => m.default || m)
  },
  {
    name: registeruNpdRfmGrmMeta?.name ?? "psychologists-checkout-slug-register___en",
    path: registeruNpdRfmGrmMeta?.path ?? "/en/psychologists/checkout/:slug()/register",
    meta: registeruNpdRfmGrmMeta || {},
    alias: registeruNpdRfmGrmMeta?.alias || [],
    redirect: registeruNpdRfmGrmMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue").then(m => m.default || m)
  },
  {
    name: registeruNpdRfmGrmMeta?.name ?? "psychologists-checkout-slug-register___es",
    path: registeruNpdRfmGrmMeta?.path ?? "/es/psychologists/checkout/:slug()/register",
    meta: registeruNpdRfmGrmMeta || {},
    alias: registeruNpdRfmGrmMeta?.alias || [],
    redirect: registeruNpdRfmGrmMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue").then(m => m.default || m)
  },
  {
    name: registeruNpdRfmGrmMeta?.name ?? "psychologists-checkout-slug-register___ru",
    path: registeruNpdRfmGrmMeta?.path ?? "/ru/psychologists/checkout/:slug()/register",
    meta: registeruNpdRfmGrmMeta || {},
    alias: registeruNpdRfmGrmMeta?.alias || [],
    redirect: registeruNpdRfmGrmMeta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/checkout/[slug]/register.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hc2ieDyy50Meta?.name ?? "psychologists-view-slug___uk",
    path: _91slug_93hc2ieDyy50Meta?.path ?? "/psychologists/view/:slug()",
    meta: _91slug_93hc2ieDyy50Meta || {},
    alias: _91slug_93hc2ieDyy50Meta?.alias || [],
    redirect: _91slug_93hc2ieDyy50Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hc2ieDyy50Meta?.name ?? "psychologists-view-slug___en",
    path: _91slug_93hc2ieDyy50Meta?.path ?? "/en/psychologists/view/:slug()",
    meta: _91slug_93hc2ieDyy50Meta || {},
    alias: _91slug_93hc2ieDyy50Meta?.alias || [],
    redirect: _91slug_93hc2ieDyy50Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hc2ieDyy50Meta?.name ?? "psychologists-view-slug___es",
    path: _91slug_93hc2ieDyy50Meta?.path ?? "/es/psychologists/view/:slug()",
    meta: _91slug_93hc2ieDyy50Meta || {},
    alias: _91slug_93hc2ieDyy50Meta?.alias || [],
    redirect: _91slug_93hc2ieDyy50Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hc2ieDyy50Meta?.name ?? "psychologists-view-slug___ru",
    path: _91slug_93hc2ieDyy50Meta?.path ?? "/ru/psychologists/view/:slug()",
    meta: _91slug_93hc2ieDyy50Meta || {},
    alias: _91slug_93hc2ieDyy50Meta?.alias || [],
    redirect: _91slug_93hc2ieDyy50Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/psychologists/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexD9QpfRa9f8Meta?.name ?? "russkiykorabl-idinahui___uk",
    path: indexD9QpfRa9f8Meta?.path ?? "/russkiykorabl-idinahui",
    meta: indexD9QpfRa9f8Meta || {},
    alias: indexD9QpfRa9f8Meta?.alias || [],
    redirect: indexD9QpfRa9f8Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue").then(m => m.default || m)
  },
  {
    name: indexD9QpfRa9f8Meta?.name ?? "russkiykorabl-idinahui___en",
    path: indexD9QpfRa9f8Meta?.path ?? "/en/russkiykorabl-idinahui",
    meta: indexD9QpfRa9f8Meta || {},
    alias: indexD9QpfRa9f8Meta?.alias || [],
    redirect: indexD9QpfRa9f8Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue").then(m => m.default || m)
  },
  {
    name: indexD9QpfRa9f8Meta?.name ?? "russkiykorabl-idinahui___es",
    path: indexD9QpfRa9f8Meta?.path ?? "/es/russkiykorabl-idinahui",
    meta: indexD9QpfRa9f8Meta || {},
    alias: indexD9QpfRa9f8Meta?.alias || [],
    redirect: indexD9QpfRa9f8Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue").then(m => m.default || m)
  },
  {
    name: indexD9QpfRa9f8Meta?.name ?? "russkiykorabl-idinahui___ru",
    path: indexD9QpfRa9f8Meta?.path ?? "/ru/russkiykorabl-idinahui",
    meta: indexD9QpfRa9f8Meta || {},
    alias: indexD9QpfRa9f8Meta?.alias || [],
    redirect: indexD9QpfRa9f8Meta?.redirect || undefined,
    component: () => import("/opt/apps/.build/apps/web/app/pages/russkiykorabl-idinahui/index.vue").then(m => m.default || m)
  }
]