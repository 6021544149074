import { Container } from "inversify";
import { seoTypes } from "../../di/types";
import { GetMetadataUseCase } from "../../features/metadata/application";

export default defineNuxtPlugin({
  name: 'seo',
  enforce: 'post',
  async setup(nuxtApp) {
    const { $container, $i18n }: { $container: Container } = nuxtApp;
    const getMetadataUseCase: GetMetadataUseCase = $container.get<GetMetadataUseCase>(seoTypes.GetMetadataUseCase);
    const metadata = await getMetadataUseCase.getAllMetadata();

    function getValueFromApi(key: string) {
      const currentPath = nuxtApp.$router.currentRoute.value.fullPath;
      const locale = $i18n.locale.value;
      const neutralCurrentPath = currentPath.replace(`/${locale}`, '/').replace('//', '/');

      if (metadata[neutralCurrentPath]) {
        if (metadata[neutralCurrentPath][key][locale]) {
          return metadata[neutralCurrentPath][key][locale];
        }
      }
    }

    return {
      provide: {
        seo: {
          getTitle(fallback: string, params: Record<string, string> = {}) {
            return getValueFromApi('title') ?? $i18n.t(fallback, params);
          },
          getDescription(fallback: string | undefined = undefined, params: Record<string, string> = {}) {
            if (fallback) {
              return getValueFromApi('description') ?? $i18n.t(fallback, params);
            } else {
              return getValueFromApi('description');
            }

          }
        }
      }
    }
  },
  hooks: {
    // You can directly register Nuxt app runtime hooks here
    'app:created'() {
      const nuxtApp = useNuxtApp()
      // do something in the hook
    }
  },
})
