import {useTextChat} from "../../../features/text/ui/web/composables/useTextChat";
import {Message} from "../../../models/message";

export default defineNuxtPlugin(async (nuxtApp ) => {
  const realtime: RealtimeService = nuxtApp.$realtime;

  nuxtApp.hook('app:mounted', async () => {
    const {session, fetchSession} = useAuthSession();

    await fetchSession().then(() => {
      if (session.value) {
        const {addMessage, selectedRoomId, fetchRooms, updateRoom} = useTextChat();

        const profileId = session.value.selectedProfile.id;
        realtime.subscribe(`private-encrypted-chat-${profileId}`, 'MessageSent', (message: Message) => {
          if (message.roomId === selectedRoomId.value) {
            addMessage(message);
          }
        });
        realtime.subscribe(`private-encrypted-chat-${profileId}`, 'RoomUpdated', (message: object) => {
          updateRoom(message);
        });
        realtime.subscribe(`private-encrypted-chat-${profileId}`, 'MessageWasRead', (message: object) => {
          // setMessageStatus(message.id, false, MessageStatus.HasBeenRead);
        });
      }
    });
  });
});
