export default {
  "cabinet-auth": () => import("/opt/apps/.build/apps/web/app/layouts/cabinet-auth.vue").then(m => m.default || m),
  "cabinet-base": () => import("/opt/apps/.build/apps/web/app/layouts/cabinet-base.vue").then(m => m.default || m),
  "cabinet-chat": () => import("/opt/apps/.build/apps/web/app/layouts/cabinet-chat.vue").then(m => m.default || m),
  "cabinet-default": () => import("/opt/apps/.build/apps/web/app/layouts/cabinet-default.vue").then(m => m.default || m),
  "cabinet-empty": () => import("/opt/apps/.build/apps/web/app/layouts/cabinet-empty.vue").then(m => m.default || m),
  "empty-blue": () => import("/opt/apps/.build/apps/web/app/layouts/empty-blue.vue").then(m => m.default || m),
  matching: () => import("/opt/apps/.build/apps/web/app/layouts/matching.vue").then(m => m.default || m),
  meeting: () => import("/opt/apps/.build/apps/web/app/layouts/meeting.vue").then(m => m.default || m),
  "public-checkout": () => import("/opt/apps/.build/apps/web/app/layouts/public-checkout.vue").then(m => m.default || m),
  public: () => import("/opt/apps/.build/apps/web/app/layouts/public.vue").then(m => m.default || m)
}