import {enforce} from "vest";
import isAlpha, {AlphaLocale} from 'validator/es/lib/isAlpha';

export function name(value: any) {
  enforce(value).message('validation.name').condition((value: string) => {
    let isValid = false;
    ['uk-UA', 'ru-RU', 'en-US'].forEach(locale => {
      if (isAlpha(value, locale as AlphaLocale, {ignore: ' '})) {
        isValid = true
      }
    });
    return isValid;
  });
}
