import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {ScheduleRepository} from "../domain";
import {Schedule} from "../../../models/schedule";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";

@injectable()
export class GetScheduleUseCase {
  constructor(
    @inject(marketplaceTypes.ScheduleRepository) private readonly scheduleRepository: ScheduleRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async forCheckout(serviceId: string): Promise<Schedule> {
    const response = await this.scheduleRepository.fetchSchedule(serviceId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        console.log(response.error);
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }
}
