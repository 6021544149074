import { addRouteMiddleware, defineNuxtPlugin } from '#app';
import protectedMiddleware from "./../middleware/protected";
import {CaslAbilityFactory} from "../../../features/acl";
import {useAuthSession} from "../composables/useAuthSession";
import {eventBusTypes} from "@meclee/eventbus/di/types";
import {EventBus} from "@meclee/eventbus";

export default defineNuxtPlugin(async () => {
  addRouteMiddleware('protected', protectedMiddleware, {
    global: false,
  });

  const { fetchSession, session, isLoggedIn } = useAuthSession();
  if (isLoggedIn.value) {
    await fetchSession();
  }

  const localePath = useLocalePath();
  const { $container } = useNuxtApp();
  const eventBus: EventBus = $container.get<EventBus>(eventBusTypes.EventBus);

  eventBus.on('refreshSessionFailed', () => {
    fetchSession();
    navigateTo(localePath(`/cabinet/auth/sign-in`));
  });

  return {
    provide: {
      session: session.value,
      can: function (subject: string, action: string, field: string|undefined) {
        const abilities = [];
        Object.values(session.value?.abilities).forEach(ability => {
          abilities.push({
            action: ability.action,
            subject: ability.subject,
            conditions: Object.assign({}, ability.conditions),
            fields: Array.from(Object.values(ability.fields)),
          });
        });

        const casl = CaslAbilityFactory.createFromAbilities(abilities);
        return casl.can(action, subject, field);
      },
    }
  }
});
