import revive_payload_client_EapZxZxuo9 from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.8.1_eslint@8.53.0_rollup@3.29.4_sass@1.69.5_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NQfT2sewti from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.8.1_eslint@8.53.0_rollup@3.29.4_sass@1.69.5_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_SGAeupohtk from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.8.1_eslint@8.53.0_rollup@3.29.4_sass@1.69.5_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_MWV2G3L6FX from "/opt/apps/.build/node_modules/.pnpm/nuxt-posthog@1.5.0_rollup@3.29.4/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.mjs";
import _0_siteConfig_Tyvp2oAVWq from "/opt/apps/.build/node_modules/.pnpm/nuxt-site-config@1.5.5_rollup@3.29.4_vue@3.3.8/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_6CRDBnSU3V from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.8.1_eslint@8.53.0_rollup@3.29.4_sass@1.69.5_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/apps/.build/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_X0qNWCC2LV from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.8.1_eslint@8.53.0_rollup@3.29.4_sass@1.69.5_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import directives_C0r8ypA9ze from "/opt/apps/.build/node_modules/.pnpm/nuxt-posthog@1.5.0_rollup@3.29.4/node_modules/nuxt-posthog/dist/runtime/plugins/directives.mjs";
import plugin_q7FpcEVXqe from "/opt/apps/.build/node_modules/.pnpm/nuxt-bugsnag@7.2.3_rollup@3.29.4/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_6koZcMrFsf from "/opt/apps/.build/node_modules/.pnpm/nuxt-delay-hydration@1.3.3_rollup@3.29.4/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_uSADQ63RCs from "/opt/apps/.build/node_modules/.pnpm/nuxt3-meta-pixel@1.0.9_rollup@3.29.4/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_Q8tUA5YntH from "/opt/apps/.build/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.8.1_rollup@3.29.4_vue@3.3.8/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_5NVPUTSO2r from "/opt/apps/.build/node_modules/.pnpm/nuxt-gtag@1.1.1_rollup@3.29.4/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_MrS4gycjFa from "/opt/apps/.build/node_modules/.pnpm/nuxt-site-config@1.5.5_rollup@3.29.4_vue@3.3.8/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import i18n_u6oKUwtfoq from "/opt/apps/.build/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@3.29.4_vue@3.3.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import container_client_nVWA1Y6TnX from "/opt/apps/.build/packages/nuxt/app/plugins/container.client.ts";
import splide_b8AOuWtdaN from "/opt/apps/.build/packages/nuxt/modules/shared/nuxt/plugins/splide.ts";
import i18n_9rrtpUksQb from "/opt/apps/.build/packages/nuxt/modules/shared/nuxt/plugins/i18n.ts";
import auth_VqUKRsm89E from "/opt/apps/.build/packages/auth/frameworks/nuxt/plugins/auth.ts";
import cabinet_employee_lg35L2OaQx from "/opt/apps/.build/packages/cabinet-employee/frameworks/nuxt/plugins/cabinet-employee.ts";
import chunk_reload_client_2GUBIjMqi9 from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.8.1_eslint@8.53.0_rollup@3.29.4_sass@1.69.5_typescript@5.2.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import realtime_e9I2iuf1QN from "/opt/apps/.build/packages/realtime/frameworks/nuxt/plugins/realtime.ts";
import vue_final_modal_Qge1y8PDAA from "/opt/apps/.build/packages/mosaic-ui/plugins/vue-final-modal.ts";
import chat_ZIOFg51LLt from "/opt/apps/.build/packages/chat/frameworks/nuxt/plugins/chat.ts";
import chartjs_client_dSP4DDlQKI from "/opt/apps/.build/packages/nuxt/app/plugins/chartjs.client.ts";
import hotjar_client_FaWQpBVy4t from "/opt/apps/.build/packages/nuxt/app/plugins/hotjar.client.ts";
import tippy_Tm6mNIN2oH from "/opt/apps/.build/packages/nuxt/app/plugins/tippy.ts";
import vue_sticky_2bKXDstOpf from "/opt/apps/.build/packages/nuxt/app/plugins/vue-sticky.ts";
import vue_loading_overlay_client_VSHfrAParA from "/opt/apps/.build/packages/nuxt/app/plugins/vue-loading-overlay.client.ts";
import vue_toastification_client_maBAOfUNg5 from "/opt/apps/.build/packages/nuxt/app/plugins/vue-toastification.client.ts";
import fingerprint_client_e9LjqsM7zD from "/opt/apps/.build/packages/nuxt/app/plugins/fingerprint.client.ts";
import container_G9eXPTg2zh from "/opt/apps/.build/apps/web/app/plugins/container.ts";
import plugin_t06Ri6tlk5 from "/opt/apps/.build/packages/seo/frameworks/nuxt/plugin.ts";
export default [
  revive_payload_client_EapZxZxuo9,
  unhead_NQfT2sewti,
  router_SGAeupohtk,
  posthog_client_MWV2G3L6FX,
  _0_siteConfig_Tyvp2oAVWq,
  payload_client_6CRDBnSU3V,
  components_plugin_KR1HBZs4kY,
  prefetch_client_X0qNWCC2LV,
  directives_C0r8ypA9ze,
  plugin_q7FpcEVXqe,
  nuxt_plugin_6koZcMrFsf,
  plugin_uSADQ63RCs,
  plugin_Q8tUA5YntH,
  plugin_client_5NVPUTSO2r,
  i18n_MrS4gycjFa,
  i18n_u6oKUwtfoq,
  container_client_nVWA1Y6TnX,
  splide_b8AOuWtdaN,
  i18n_9rrtpUksQb,
  auth_VqUKRsm89E,
  cabinet_employee_lg35L2OaQx,
  chunk_reload_client_2GUBIjMqi9,
  realtime_e9I2iuf1QN,
  vue_final_modal_Qge1y8PDAA,
  chat_ZIOFg51LLt,
  chartjs_client_dSP4DDlQKI,
  hotjar_client_FaWQpBVy4t,
  tippy_Tm6mNIN2oH,
  vue_sticky_2bKXDstOpf,
  vue_loading_overlay_client_VSHfrAParA,
  vue_toastification_client_maBAOfUNg5,
  fingerprint_client_e9LjqsM7zD,
  container_G9eXPTg2zh,
  plugin_t06Ri6tlk5
]