
// @ts-nocheck


export const localeCodes =  [
  "uk",
  "en",
  "es",
  "ru"
]

export const localeLoaders = {
  "uk": [{ key: "../../../packages/locales/uk.json", load: () => import("../../../packages/locales/uk.json" /* webpackChunkName: "locale__opt_apps__build_packages_locales_uk_json" */), cache: true }],
  "en": [{ key: "../../../packages/locales/en.json", load: () => import("../../../packages/locales/en.json" /* webpackChunkName: "locale__opt_apps__build_packages_locales_en_json" */), cache: true }],
  "es": [{ key: "../../../packages/locales/es.json", load: () => import("../../../packages/locales/es.json" /* webpackChunkName: "locale__opt_apps__build_packages_locales_es_json" */), cache: true }],
  "ru": [{ key: "../../../packages/locales/ru.json", load: () => import("../../../packages/locales/ru.json" /* webpackChunkName: "locale__opt_apps__build_packages_locales_ru_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../../../packages/nuxt/nuxt-i18n.js?hash=3105e31c&config=1" /* webpackChunkName: "_opt_apps__build_packages_nuxt_nuxt_i18n_js_3105e31c" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "/opt/apps/.build/packages/nuxt/nuxt-i18n.js",
  "locales": [
    {
      "code": "uk",
      "name": "Українська",
      "iso": "uk",
      "files": [
        "../../../packages/locales/uk.json"
      ]
    },
    {
      "code": "en",
      "name": "English",
      "iso": "en",
      "files": [
        "../../../packages/locales/en.json"
      ]
    },
    {
      "code": "es",
      "name": "Español",
      "iso": "es",
      "files": [
        "../../../packages/locales/es.json"
      ]
    },
    {
      "code": "ru",
      "name": "Русский",
      "iso": "ru",
      "files": [
        "../../../packages/locales/ru.json"
      ]
    }
  ],
  "defaultLocale": "uk",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "./../../../packages/locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "uk",
    "name": "Українська",
    "iso": "uk",
    "files": [
      {
        "path": "../../../packages/locales/uk.json"
      }
    ]
  },
  {
    "code": "en",
    "name": "English",
    "iso": "en",
    "files": [
      {
        "path": "../../../packages/locales/en.json"
      }
    ]
  },
  {
    "code": "es",
    "name": "Español",
    "iso": "es",
    "files": [
      {
        "path": "../../../packages/locales/es.json"
      }
    ]
  },
  {
    "code": "ru",
    "name": "Русский",
    "iso": "ru",
    "files": [
      {
        "path": "../../../packages/locales/ru.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
